import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCities, getEndUsersList, getSiteType, getSlaPolicies, getCityFromPincode, updateInventorySite, removePinCityFromStore, removeEnduserListFromStore, createNewBranchRequest } from '../../../actions/inventory';
import { closeModalAction } from '../../../actions/modal';
import { debounce } from 'lodash';
import { actualValueForDropdown, getSelectedItemFromDropdownList, isLooksLikeId } from '../../../utils/common';
import { sendToastMessage } from '../../../actions/toast';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import Select from '../../../common/Form/Select';
import { useForm, useFormContext } from 'react-hook-form';
import apis from '../../../apis';
import { FetchGoogleAddress } from '../../SupportTrack/RenderTicketCard';
import { addNewBranch, RenderBranchName } from '../../Ticket/Modal/AddNewSite';
const formName = 'editInventorySite';

const EditInventorySite = ({ modalData, customerId, source, additionalToast }) => {
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading);
    const { cities, endUsersList, slas, branchList } = useSelector((state) => state.inventory);

    const siteDetails = useMemo(() => {
        let site = {};
        if (source === 'edit_ticket' && modalData) {
            site = {
                id: modalData.value,
                siteId: modalData.label,
                address: modalData.address,
                site_branch_name: modalData.branch,
                fk_pincode_id: modalData.fk_pincode_id,
                siteContactEmail: modalData.contactEmail,
                siteContactPhone: modalData.contactMobile,
                siteContactName: modalData.contactName,
                customer_end_user: modalData.customer_end_user,
                fk_city_id: modalData.cityId,
                fk_state_id: modalData?.fk_state_id,
                external_site_id: modalData?.label,
                customer_id: modalData.fk_customer_id,
                site_vertical: modalData.site_vertical,
                sla: modalData.sla,
                enduser: { id: modalData.customer_end_user }
            };
        } else if (modalData && source !== 'edit_ticket') site = modalData;
        return site;
    }, [modalData, source])

    const getDataOnMount = useCallback(() => {
        dispatch(getAllCities());
        dispatch(getEndUsersList({ customer_id: siteDetails?.customer_id }));
        dispatch(getSiteType());
        dispatch(getSlaPolicies({ customerId: siteDetails?.customer_id, dropdown: true }));
    }, [dispatch, siteDetails?.customer_id]);

    useEffect(() => {
        getDataOnMount();
        return () => {
            dispatch(removePinCityFromStore());
            dispatch(removeEnduserListFromStore());
        }
    }, [getDataOnMount, dispatch]);

    const defaultValues = {
        complete_site_address: siteDetails.address,
        external_site_id: siteDetails.siteId,
        fk_pincode_id: siteDetails.fk_pincode_id,
        fk_state_id: siteDetails.fk_state_id,
        site_branch_name: siteDetails.site_branch_name,
        site_contact_person_email: siteDetails.siteContactEmail,
        site_contact_person_mobile: siteDetails.siteContactPhone,
        site_contact_person_name: siteDetails.siteContactName,
        site_type: '',
        site_vertical: siteDetails.site_vertical,
        sla: getSelectedItemFromDropdownList(siteDetails.sla, slas),
        customer_end_user: getSelectedItemFromDropdownList(parseInt(siteDetails.customer_end_user), endUsersList),
        fk_city_id: getSelectedItemFromDropdownList(siteDetails.fk_city_id, cities),
    };

    const submit = (form) => {
        const fk_customer_id = siteDetails.customer_id;
        const site_id = siteDetails.id;
        let sites = {
            fk_customer_id,
            complete_site_address: form.complete_site_address,
            customer_end_user: actualValueForDropdown(form.customer_end_user),
            external_site_id: form.external_site_id,
            fk_city_id: actualValueForDropdown(form.fk_city_id),
            fk_pincode_id: form.fk_pincode_id,
            fk_state_id: form.fk_state_id,
            site_contact_person_email: form.site_contact_person_email,
            site_contact_person_mobile: form.site_contact_person_mobile,
            site_contact_person_name: form.site_contact_person_name,
            site_vertical: form.site_vertical,
            sla: actualValueForDropdown(form.sla),
        };

        if (form.site_branch_name_select && actualValueForDropdown(form.site_branch_name_select) !== addNewBranch.value) {
            sites.site_branch_name = actualValueForDropdown(form.site_branch_name_select);
        } else {
            sites.site_branch_name = form.site_branch_name;
        }

        if (actualValueForDropdown(form.site_branch_name_select) && form.site_branch_name && branchList.length > 0) {
            dispatch(
                createNewBranchRequest({
                    branchName: sites.site_branch_name,
                    endUserId: actualValueForDropdown(form.customer_end_user),
                    customerId: actualValueForDropdown(form.fk_customer_id),
                })
            );
        }
        const isValidCustomerId = isLooksLikeId(fk_customer_id);
        const isValidSiteId = isLooksLikeId(site_id);

        if (isValidCustomerId && isValidSiteId) {
            dispatch(updateInventorySite({ data: sites, siteId: site_id, customerId, formName, source, additionalToast }));
        } else dispatch(sendToastMessage({ status: 'danger', message: 'Invalid Data' }));
    };
    const closeModal = () => {
        dispatch(closeModalAction());
    };

    const methods = useForm({ defaultValues, mode: 'all' });
    const { setValue } = methods;

    useEffect(() => {
        if (siteDetails?.enduser?.id && Array.isArray(endUsersList) && endUsersList.length > 0) {
            const findEnduser = endUsersList.find((enduser) => enduser.value === siteDetails?.enduser?.id);
            if (findEnduser) {
                setValue('customer_end_user', findEnduser)
            }
            const findSla = getSelectedItemFromDropdownList(siteDetails.sla, slas);
            if (findSla) setValue('sla', findSla);
            const finCity = getSelectedItemFromDropdownList(siteDetails.fk_city_id, cities);
            if (finCity) setValue('fk_city_id', finCity);
        }
    }, [setValue, siteDetails?.enduser?.id, endUsersList, slas, siteDetails?.sla, siteDetails?.fk_city_id, cities]);


    return (
        <>
            <Form
                className='w-full'
                onSubmit={(e) => {
                    submit(e);
                }}
                onCancel={closeModal}
                defaultValues={defaultValues}
                submitButtonText={'Update'}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                methods={methods}
            >
                {source === 'edit_ticket' && <p className='text-scogoorange text-font13 font-medium px-4 pb-1'>On changing the details of an existing site, all linked ticket sites will also be updated.</p>}
                <EditSiteInputs site_branch_name={siteDetails.site_branch_name} customer_end_user={siteDetails.customer_end_user} fk_customer_id={modalData.fk_customer_id} />
            </Form>
        </>
    );
};

export default EditInventorySite;

const EditSiteInputs = ({ fk_customer_id, customer_end_user, site_branch_name }) => {
    const dispatch = useDispatch();
    const { cities, endUsersList, slas, branchList } = useSelector((state) => state.inventory);
    const { setValue } = useFormContext();

    const fetchPincode = (pincode) => {
        if (pincode.length === 6) {
            dispatch(getCityFromPincode({ pincode: pincode }));
        }
    };

    const debouncedPincodeHandler = useCallback(debounce(fetchPincode, 300), []);


    const memoizedBranchList = useMemo(() => {
        const list = [addNewBranch];
        if (Array.isArray(branchList) && branchList.length > 0) {
            branchList.forEach((branch) => {
                list.push({ key: branch.id, label: branch.site_branch_name, value: branch.site_branch_name });
            });
        }
        return list;
    }, [branchList]);


    useEffect(() => {
        if (memoizedBranchList && memoizedBranchList.length > 0 && site_branch_name) {
            const findBranch = memoizedBranchList.find(({ value }) => value === site_branch_name);
            if (findBranch) setValue('site_branch_name_select', findBranch);
        } else if (site_branch_name) {
            setValue('site_branch_name', site_branch_name);
        }
    }, [site_branch_name, memoizedBranchList, setValue]);

    return (
        <div className='px-3'>
            <div className='grid grid-cols-3 rows-fr grid-flow-row-dense'>
                <Input label='External Site Id' name='external_site_id' type={'text'} options={endUsersList} placeholder={'External Site Id'} />
                <Select label='End User' name='customer_end_user' options={endUsersList} required />
                <RenderBranchName branchList={memoizedBranchList} customer_end_user={customer_end_user} customer={fk_customer_id} />
                <Input
                    label='Pin Code'
                    type={'number'}
                    name='fk_pincode_id'
                    required
                    placeholder='Pincode'
                    onChange={(event) => debouncedPincodeHandler(event.target.value)}
                    pattern={/^\d{6}$/}
                    validate={async (value) => {
                        if (value === '') return true;
                        const response = await apis.cities.getCityFromPincode({}, {}, {}, { pincode: value });
                        let pinCity = response.data.data;
                        if (pinCity) {
                            let findCity = cities.find((city) => city.value === pinCity?.fk_city_id);
                            if (findCity) setValue('fk_city_id', findCity);
                            if (pinCity?.fk_state_id) setValue('fk_state_id', pinCity.fk_state_id);
                        }
                        return response.data?.code === 200 || "Pincode does't exist in the System.";
                    }}
                />
                <Select label='City' required name='fk_city_id' options={cities} />

                <div>

                    <FetchGoogleAddress name='complete_site_address' label='Site Address' required setLocation={({ pincode }) => setValue('fk_pincode_id', pincode)} />
                </div>

                <Input label='Site Vertical' name='site_vertical' type={'text'} />
                <Select label='SLA' name='sla' options={slas} />

                <Input label='Contact Name' type={'text'} name='site_contact_person_name' />
                <Input
                    label='Contact Mobile'
                    type={'number'}
                    name='site_contact_person_mobile'
                    validate={(mobile) => {
                        if (mobile === '') return true;
                        return mobile.length === 10 || 'Invalid Mobile';
                    }}
                />
                <Input
                    label='Contact Email'
                    type={'text'}
                    name='site_contact_person_email'
                    validate={(email) => {
                        if (email === '') return true;
                        return (
                            email.match(
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            ) || 'Invalid Email'
                        );
                    }}
                />
            </div>
        </div>
    );
};
