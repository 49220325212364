import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { approveWalletPayment } from '../../../../actions/wallet';
import Buttons from '../../../../common/Buttons';
import IconToolTip from '../../../../common/IconToolTip';
import StatusLabel from '../../reusableComponents/StatusLabel';
import { statusLookup } from './editPayoutContainer';
import _groupBy from 'lodash/groupBy';
import { getTicketPayments, processAdditionalAmount, requestAdditionalAmount } from '../../../../actions/tickets';
import { additionalPaymentStatus, paymentMode } from '../../PaymentController';
import { IconButton } from './IconButton';
import OverflowTip from '../../../../common/OverflowTip';
import { isApprovePaymentAllowed, isEditAllowedInAdditonalPayament } from '../../../../utils/access';
import { isCluster, isPsp, isScm, isSuperVisor } from '../../../../utils/role';
import { useForm, useFormContext } from 'react-hook-form';
import { closeModalAction, openCustomModal } from '../../../../actions/modal';
import TextArea from '../../../../common/Form/TextArea';
import Form from '../../../../common/Form';
import { validateNumberValue, validateStringValue } from '../../../../utils/common';
import { copyToClipboard } from '../../../../actions/utils';


export const Actions = (props) => {
    const { payment, idx, onDeleteClick, isUserEditing, setIsEditingRow, ticketId, arrayName, eligibleSPsDropdown, custom_user_ids, onSuccess } = props;
    const { payNowWalletLoading, rejectWalletLoading, formButtonLoading, isTicketPaymentLoading } = useSelector(
        (state) => state.loading
    );
    const { ticketBasePayment, ticketPayment, ticketDetails } = useSelector((state) => state.tickets);
    const [individualRowHistoricData, setIndividualRowHistoricData] = useState([]);
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id
    const type = loggedUser.type

    const dispatch = useDispatch();
    let { statusColor, statusContent } = statusLookup[payment?.status];
    const { setValue, watch } = useFormContext();
    const editPayout = watch(arrayName);

    const onUpdateCancel = ({ e, idx, refrenceId }) => {
        e.preventDefault();
        individualRowHistoricData.forEach((data) => {
            if (data.referenceId === refrenceId) {
                setIsEditingRow((current) => current.filter((id) => id !== idx));
                let updatedEditPayout = editPayout.map((payout, payoutIdx) => {
                    if (payout.referenceId === refrenceId) {
                        return {
                            ...payout,
                            ...data,
                        };
                    }
                    return payout;
                });
                setValue('editPayout', updatedEditPayout)

            }
        });
    };

    const onPayNow = (e) => {
        e.preventDefault();
        const payload = {
            scogo_transaction_no: ticketBasePayment?.scogo_transaction_no,
            action: 'Approve',
            due_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch(approveWalletPayment({ data: payload, instantpay: true, isRefresh: true }));
    };

    const onRejectBasePayment = (e) => {
        e.preventDefault();
        const payload = {
            action: 'Reject',
            scogo_transaction_no: ticketBasePayment?.scogo_transaction_no,
        };
        dispatch(approveWalletPayment({ data: payload, isRefresh: true }));
    };

    const onApproveOrRejectHandler = ({ additionalPayment, payNow = false, action, idx, processed_remark, formName, openRemark }) => {
        const transactions = [];
        const paymentGroups = _groupBy(ticketPayment, 'referenceId');
        if (additionalPayment && additionalPayment?.referenceId && paymentGroups) {
            paymentGroups[additionalPayment.referenceId]?.map((payment) => {
                if ([additionalPaymentStatus.pending, additionalPaymentStatus.scheduled].includes(payment.status)) {
                    const data = {
                        id: payment.id,
                        scheduled_date: moment().add(7, 'days').format('YYYY-MM-DD'),
                        amount: parseFloat(payment.orderAmount),
                    };
                    if (!['', undefined, null].includes(additionalPayment.scheduled_on) && moment(additionalPayment.scheduled_on).isValid()) {
                        data.scheduled_date = moment(additionalPayment.scheduled_on).format('YYYY-MM-DD');
                    } else if (!['', undefined, null].includes(payment.scheduled_on) && moment(payment.scheduled_on).isValid()) {
                        data.scheduled_date = moment(payment.scheduled_on).format('YYYY-MM-DD');
                    }
                    if (payNow === true) {
                        data.scheduled_date = moment().format('YYYY-MM-DD');
                    }
                    if (payment.paymentMode === paymentMode.customer && payment?.fk_user_id === ticketDetails?.customer_details?.fk_user_id) {
                        if (!['', undefined, null].includes(additionalPayment.customer_details) && !isNaN(additionalPayment.customer_details)) {
                            data.amount = parseFloat(additionalPayment.customer_details);
                        }
                    }
                    if (payment.paymentMode === paymentMode.partner && payment.fk_user_id === ticketDetails?.accepted_sp_id) {
                        if (!['', undefined, null].includes(additionalPayment.partner_details) && !isNaN(additionalPayment.partner_details)) {
                            data.amount = parseFloat(additionalPayment.partner_details);
                        }
                    }
                    if (payment.paymentMode === paymentMode.cluster && payment.fk_user_id === ticketDetails?.cluster_id) {
                        if (!['', undefined, null].includes(additionalPayment.cluster_details) && !isNaN(additionalPayment.cluster_details)) {
                            data.amount = parseFloat(additionalPayment.cluster_details);
                        }
                    }
                    if (payment.paymentMode === paymentMode.psp && payment.fk_user_id === ticketDetails?.psp_id) {
                        if (!['', undefined, null].includes(additionalPayment.psp_details) && !isNaN(additionalPayment.psp_details)) {
                            data.amount = parseFloat(additionalPayment.psp_details);
                        }
                    }
                    if (payment.paymentMode === paymentMode.supervisor && payment.fk_user_id === ticketDetails?.supervisor_id) {
                        if (!['', undefined, null].includes(additionalPayment.supervisor_details) && !isNaN(additionalPayment.supervisor_details)) {
                            data.amount = parseFloat(additionalPayment.supervisor_details);
                        }
                    }
                    if (payment.paymentMode === paymentMode.warehouseOwner && payment.fk_user_id === ticketDetails?.warehouse_owner) {
                        if (!['', undefined, null].includes(additionalPayment.warehouse_details) && !isNaN(additionalPayment.warehouse_details)) {
                            data.amount = parseFloat(additionalPayment.warehouse_details);
                        }
                    }
                    if (payment.paymentMode === paymentMode.partner && additionalPayment?.custom_amounts?.[payment.fk_user_id]) {
                        if (!isNaN(parseFloat(additionalPayment?.custom_amounts?.[payment.fk_user_id]?.amount))) {
                            data.amount = parseFloat(additionalPayment?.custom_amounts?.[payment.fk_user_id].amount);
                        }
                    }
                    transactions.push(data);
                }
            });
            const payload = { action: action, ticketId, transactions, processed_remark };
            if (openRemark) {
                return openRemarkPopup({
                    formName, onClick: ({ processed_remark }) => {
                        payload.processed_remark = processed_remark;
                        dispatch(processAdditionalAmount({ data: payload, refreshPayments: true, closeModal: true, idx, formName }));
                    }
                })
            }

            dispatch(processAdditionalAmount({ data: payload, refreshPayments: true, closeModal: false, idx, formName }));
        }
    };

    const openRemarkPopup = ({ formName, onClick }) => {
        dispatch(
            openCustomModal({
                heading: 'Remark',
                modalHeight: 'auto',
                modalWidth: '40rem',
                modalComponent: <RemarkForm formName={formName} onClick={onClick} />
            })
        )
    }

    const handlePay = ({ event, idx, processed_remark, formName }) => {
        const data = editPayout[idx]
        if (event) event.preventDefault();
        if (!data?.category?.value || !data?.remark) return alert('Remark and Category Required');
        let amountDetails = [data?.partner_details, data?.cluster_details, data?.customer_details, data?.psp_details, data?.supervisor_details, data?.warehouse_details, ...Object.entries(data?.custom_amounts || {}).filter(([key]) => custom_user_ids.includes(parseInt(key, 10))).map(([key, value]) => value.amount)];
        amountDetails = amountDetails.filter((amount) => ![0, undefined, ''].includes(amount));
        if (amountDetails.length === 0) return alert('At least one payment is required');
        let transactions = [];
        let proofs = [];
        let proofName = '';
        if (data.proof && data.proof && typeof data.proof === 'object') {
            let proof = [...data?.proof];
            proof?.map((file) => proofs.push(file));
            proofName = proof?.[0]?.name;
        }
        if (!['', 0, undefined, null].includes(data.partner_details)) {
            transactions.push({
                amount: data.partner_details ? parseFloat(data.partner_details) : 0,
                beneficiary_id: ticketDetails?.accepted_sp_id,
                beneficiary_transaction_id: data.partner_beneficiary_transaction_id ? data.partner_beneficiary_transaction_id : 0,
                beneficiary_type: paymentMode.partner,
            });
        }
        if (!['', 0, undefined, null].includes(data.cluster_details)) {
            transactions.push({
                amount: data.cluster_details ? parseFloat(data.cluster_details) : 0,
                beneficiary_id: ticketDetails?.cluster_id,
                beneficiary_transaction_id: data.cluster_beneficiary_transaction_id ? data.cluster_beneficiary_transaction_id : 0,
                beneficiary_type: paymentMode.cluster,
            });
        }
        if (!['', 0, undefined, null].includes(data.customer_details)) {
            transactions.push({
                amount: data.customer_details ? parseFloat(data.customer_details) : 0,
                beneficiary_id: ticketDetails?.customer_details.customer_id,
                beneficiary_transaction_id: data.customer_beneficiary_transaction_id ? data.customer_beneficiary_transaction_id : 0,
                beneficiary_type: paymentMode.customer,
            });
        }
        if (!['', 0, undefined, null].includes(data.psp_details)) {
            transactions.push({
                amount: data.psp_details ? parseFloat(data.psp_details) : 0,
                beneficiary_id: ticketDetails?.psp_id,
                beneficiary_transaction_id: data.psp_beneficiary_transaction_id ? data.psp_beneficiary_transaction_id : 0,
                beneficiary_type: paymentMode.psp,
            });
        }
        if (!['', 0, undefined, null].includes(data.supervisor_details)) {
            transactions.push({
                amount: data.supervisor_details ? parseFloat(data.supervisor_details) : 0,
                beneficiary_id: ticketDetails?.supervisor_id,
                beneficiary_transaction_id: data.supervisor_beneficiary_transaction_id ? data.supervisor_beneficiary_transaction_id : 0,
                beneficiary_type: paymentMode.supervisor,
            });
        }
        if (!['', 0, undefined, null].includes(data.warehouse_details)) {
            transactions.push({
                amount: data.warehouse_details ? parseFloat(data.warehouse_details) : 0,
                beneficiary_id: ticketDetails?.warehouse_owner,
                beneficiary_transaction_id: data.warehouse_owner_beneficiary_transaction_id ? data.warehouse_owner_beneficiary_transaction_id : 0,
                beneficiary_type: paymentMode.warehouseOwner,
            });
        }
        if (!['', 0, undefined, null].includes(data.custom_amounts)) {
            Object.entries(data.custom_amounts).filter(([key]) => custom_user_ids.includes(parseInt(key, 10))).forEach(([key, value]) => {
                if (parseFloat(value.amount) >= 0 || parseFloat(value.amount) < 0) {
                    const cu = eligibleSPsDropdown?.find(u => `${u.id}` === key);
                    transactions.push({
                        amount: value.amount ? parseFloat(value.amount) : 0,
                        beneficiary_id: cu.id,
                        beneficiary_transaction_id: value.beneficiary_transaction_id ? value.beneficiary_transaction_id : 0,
                        beneficiary_type: paymentMode.partner,
                        is_additional_user: true,

                    });
                }
            })
        }

        let payment = {
            category: data?.category?.value ? data.category.value : data?.category,
            remark: data?.remark,
            scheduled_date: moment(data?.scheduled_on).format('YYYY-MM-DD'),
            transactions,
            processed_remark
        }
        if (proofName) payment.proof = proofName;

        let payments = [payment];

        let dataPayload = {
            ticketId,
            payments,
        };

        let payload = { data: dataPayload, proofs, closeModal: false, idx, formName };

        payload.onSuccess = onSuccess;
        payload.data.payments[0].processed_remark = data?.remark;
        dispatch(requestAdditionalAmount(payload));
    };

    const getAdditionalPaymentEditIcon = ({ payment, idx }) => {
        if (ticketDetails?.is_signoff_accept === 0 || (ticketDetails?.is_signoff_accept === 1 && isEditAllowedInAdditonalPayament(loggedUser.frontend_controller))) {
            return (
                <>
                    <IconToolTip title={`Edit`}>
                        <div>
                            <span
                                className='material-icons material-icons-outlined cursor-pointer text-scogoprimary text-font14 mr-1 '
                                onClick={() => {
                                    setIsEditingRow((oldIdx) => [...oldIdx, idx]);
                                    setIndividualRowHistoricData((prevState) => {
                                        return [...prevState, payment];
                                    });
                                }}
                            >
                                edit
                            </span>
                        </div>
                    </IconToolTip>
                </>
            );
        }
        return <></>
    };

    const getPaymentUpdatedBy = (payment) => {
        if (['isScheduled', 'isApproved', 'isRejected'].includes(payment.status) && payment?.processedByDetails) {
            let { first_name, last_name } = payment?.processedByDetails;
            return (
                <>
                    <OverflowTip
                        someLongText={
                            <small>
                                by <b>{`${first_name} ${last_name}`}</b>
                            </small>
                        }
                    />
                    {payment?.processed_at && (
                        <OverflowTip
                            someLongText={
                                <small>
                                    at <b>{`${moment(payment?.processed_at).format('DD-MM-YYYY')}`}</b>
                                </small>
                            }
                        />
                    )}
                </>
            );
        }
        return <></>;
    };

    const isToShowBasePaymentAction = (payment) => payment.type === 'Base Payment';
    const isToShowPayButtons = (payment) => payment.isDeletable;
    const isToShowUpdateAndCancel = (payment, idx) => payment.type === 'Additional Payment' && ['isScheduled', 'isPendingProcessed', 'isRequested'].includes(payment.status) && isUserEditing(idx);
    const isToShowPayAndCancelButtons = (payment, idx) => payment.type === 'Additional Payment' && payment.status === 'isScheduled' && !isUserEditing(idx);
    const isToShowApproveAndRejectButtons = (payment, idx) => payment.type === 'Additional Payment' && payment.status === 'isPendingProcessed' && !isUserEditing(idx);
    let showApproveRejectButtons = isToShowApproveAndRejectButtons(payment, idx);
    let showPayAndCancelButton = isToShowPayAndCancelButtons(payment, idx);
    const approvalPermission = isApprovePaymentAllowed(loggedUser.frontend_controller);

    let totalAmountOfTicket = validateNumberValue(ticketDetails?.additional_amount_paid) + validateNumberValue(ticketDetails?.request_additional_amount);

    if (showApproveRejectButtons && loggedUser.additional_amount_approval_limit !== -1) showApproveRejectButtons = totalAmountOfTicket <= loggedUser.additional_amount_approval_limit;
    if (showPayAndCancelButton && loggedUser.additional_amount_approval_limit !== -1) showPayAndCancelButton = totalAmountOfTicket <= loggedUser.additional_amount_approval_limit;

    const statusTag = <StatusLabel content={statusContent} color={statusColor} />;

    if (payment.type === 'WITHDRAW') {
        const copyTransaction = () => {
            let content = '';
            const data = payment.all_payment_data;
            let sender = 'Scogo';
            if (loggedUser.manage_partner_payments === 1 && loggedUser.customer_company_name) {
                sender = loggedUser.customer_company_name;
            }

            let payee = 'You';
            if (data.user) {
                payee = `${validateStringValue(data.user.first_name)} ${validateStringValue(data.user.last_name)}`
                if (!payee.trim()) {
                    payee = `${validateStringValue(data.user.mobile)}`
                }
            }

            content = '';
            content += `${sender} has paid ${payee}\n`;
            content += `Amount: ${data.orderAmount}\n`;
            content += `Mode of Payment: ${data.paymentMode}\n`;
            content += `${sender} Transaction No: ${data.scogo_tx_no}\n`;
            if (ticketDetails.job_ticket_number) content += `Ticket Number: ${ticketDetails.job_ticket_number}\n`;
            content += `Transaction Time: ${moment(data.txTime).add(5.50, 'hours').format('DD-MM-YYYY HH:mm:ss')}\n`;
            if (data.txMsg) {
                content += `Remark: ${data.txMsg}`;
            }
            dispatch(copyToClipboard({ data: content }))
        }


        const onRefreshClick = () => {
            dispatch(getTicketPayments({ ticketId: ticketId }));
        }

        return <div className='flex items-center'>
            {statusTag}
            <IconToolTip placement={'bottom'} title={`Copy Serial Number`}>
                <span className='hover:text-scogoorange cursor-pointer material-icons text-scogobgsky text-lg pl-1' onClick={copyTransaction}>
                    content_copy
                </span>
            </IconToolTip>
            {payment.status === 'isWithdrawlPending' && <IconToolTip placement={'bottom'} title={`Refresh`}>
                <span className={`${isTicketPaymentLoading ? 'animate-spin' : ''} hover:text-scogoorange cursor-pointer material-icons text-scogobgsky text-lg pl-1`} onClick={onRefreshClick}>
                    refresh
                </span>
            </IconToolTip>}

        </div>
    }

    if (isToShowBasePaymentAction(payment, idx)) {
        return (
            <>
                {statusTag}
                {approvalPermission && payment.status === 'isScheduled' && (
                    <div className='flex gap-2 items-center ml-2 '>
                        <IconButton
                            loader={payNowWalletLoading}
                            onClick={(e) => {
                                onPayNow(e);
                            }}
                            onHoverTitle={'Instant Pay'}
                            iconName={'done'}
                            backgroundColor={'bg-labelLightGreen text-scogo2e border border-scogo2e greenlinear-gradient'}
                            loaderSize='12'
                        />
                        <IconButton
                            loader={rejectWalletLoading}
                            onClick={(e) => {
                                onRejectBasePayment(e);
                            }}
                            onHoverTitle={'Reject'}
                            iconName={'close'}
                            backgroundColor={'bg-scogoLightRed text-scogoclosed border border-scogoclosed redlinear-gradient'}
                            loaderSize='12'
                        />
                    </div>
                )}
            </>
        );
    } else if (showApproveRejectButtons) {
        const approveFormName = `approve${payment.id}`
        const rejectFormName = `reject${payment.id}`
        return (
            <div >
                {approvalPermission ? (
                    <>
                        <div className='flex gap-3 items-center'>
                            <Buttons
                                buttonClass={`text-sm rounded-lg bg-scogoprimary text-white  text-white px-3 py-1 hover:bg-scogobgsky`} disabledClass='text-sm rounded-lg  px-3 py-1 flex items-center justify-center relative cursor-not-allowed border-scogoddd bg-scogoddd'
                                onClick={() => onApproveOrRejectHandler({ additionalPayment: payment, action: 'APPROVE', idx, openRemark: approvalPermission, formName: approveFormName })}
                            >
                                Approve
                            </Buttons>
                            <Buttons
                                buttonClass={`text-scogoclosed border border-scogoclosed flex items-center justify-center rounded-lg text-sm hover:bg-scogoLightRed cursor-pointer px-2 py-1`}
                                disabledClass='text-sm rounded-lg  px-3 py-1 flex items-center justify-center relative cursor-not-allowed border-scogoddd bg-scogoddd'
                                onClick={() => onApproveOrRejectHandler({ additionalPayment: payment, action: 'REJECT', idx, formName: rejectFormName, openRemark: approvalPermission })}
                            >
                                Reject
                            </Buttons>
                            {getAdditionalPaymentEditIcon({ payment, idx })}
                        </div>
                    </>
                ) : (
                    statusTag
                )
                }
            </div >
        );
    } else if (isToShowPayButtons(payment, idx)) {
        const formName = `request${payment.id}`
        return (
            <div className='flex items-center gap-3'>
                <Buttons
                    buttonClass={`text-font09 rounded-lg bg-scogoprimary text-white hover:bg-scogobgsky text-scogoprimary px-4 py-2`}
                    onClick={(event) => handlePay({ event, idx, formName })}
                    loading={formButtonLoading[formName]}
                    disabledClass='text-font09 rounded-lg  px-4 py-2 flex items-center justify-center relative cursor-not-allowed border-scogoddd bg-scogoddd'
                >
                    Request
                </Buttons>
                <span
                    onClick={onDeleteClick}
                    className={`material-icons text-scogoToastDangerIcon cursor-pointer hover:text-scogoprimary`}
                >
                    delete
                </span>
            </div>
        );
    } else if (showPayAndCancelButton) {
        const approveFormName = `approve${payment.id}`
        const rejectFormName = `reject${payment.id}`
        return (
            <>
                <div className='flex items-center gap-3'>{statusTag}</div>
                {approvalPermission && (
                    <div className='flex items-center gap-3 py-2'>
                        <IconButton
                            onClick={(e) => {
                                e.preventDefault()
                                onApproveOrRejectHandler({ processed_remark: payment.processed_remark, additionalPayment: payment, payNow: true, action: 'APPROVE', idx, formName: approveFormName, openRemark: approvalPermission && !payment.processed_remark });
                            }}
                            onHoverTitle={'Instant Pay'}
                            iconName={'done'}
                            backgroundColor={'bg-labelLightGreen text-scogo2e border border-scogo2e greenlinear-gradient'}
                            loaderSize='12'
                            loader={payment.processed_remark ? formButtonLoading[approveFormName] : false}
                        />
                        <IconButton
                            onClick={(e) => {
                                e.preventDefault()
                                onApproveOrRejectHandler({ processed_remark: payment.processed_remark, additionalPayment: payment, action: 'REJECT', idx, formName: rejectFormName, openRemark: approvalPermission && !payment.processed_remark });
                            }}
                            onHoverTitle={'Reject'}
                            iconName={'close'}
                            backgroundColor={'bg-scogoLightRed text-scogoclosed border border-scogoclosed redlinear-gradient'}
                            loaderSize='12'
                            loader={payment.processed_remark ? formButtonLoading[rejectFormName] : false}
                        />
                        <div className='py-2'>{getAdditionalPaymentEditIcon({ payment, idx })}</div>
                    </div>
                )}
                {getPaymentUpdatedBy(payment)}
            </>
        );
    } else if (isToShowUpdateAndCancel(payment, idx)) {
        const formName = `save${payment.id}`
        return (
            <>
                <div className='flex items-center gap-3 py-2'>
                    <Buttons
                        buttonClass={`text-font09 rounded-lg bg-scogoprimary text-white  px-6 py-2`}
                        disabledClass='text-font09 rounded-lg px-6 py-2 flex items-center justify-center relative cursor-not-allowed border-scogoddd bg-scogoddd'
                        onClick={() => handlePay({ idx, formName })}
                        loading={formButtonLoading[formName]}
                    >
                        Save
                    </Buttons>
                    <span
                        className='material-icons material-icons-outlined cursor-pointer text-scogoclosed text-font16 mr-1 hover:text-scogoorange '
                        onClick={(e) => onUpdateCancel({ e, refrenceId: payment.referenceId, idx })}
                    >
                        close
                    </span>
                </div>
            </>
        );
    } else if (payment.type === 'Additional Payment' && payment.status === 'isRejected') {
        return <div>
            {statusTag}
            {getPaymentUpdatedBy(payment)}
        </div>
    }

    const showRejectOrEdit = payment.status !== 'isApproved' && (isScm(role, type) || isCluster(role, type) || isSuperVisor(role, type) || isPsp(role, type))

    return (
        <div>
            {statusTag}
            {getPaymentUpdatedBy(payment)}
            {showRejectOrEdit && <div className='flex gap-2 items-center'>
                <Buttons
                    buttonClass={`text-scogoclosed border border-scogoclosed flex items-center justify-center rounded-lg text-sm hover:bg-scogoLightRed cursor-pointer px-2 py-1`}
                    disabledClass='text-sm rounded-lg  px-3 py-1 flex items-center justify-center relative cursor-not-allowed border-scogoddd bg-scogoddd'
                    onClick={() => onApproveOrRejectHandler({ additionalPayment: payment, action: 'REJECT', idx, openRemark: true, formName: `reject${payment.id}` })}
                >
                    Reject
                </Buttons>
                <div>{getAdditionalPaymentEditIcon({ payment, idx })}</div>
            </div>}
        </div>
    );
};


const RemarkForm = ({ onClick, formName }) => {
    const defaultValues = {};
    const dispatch = useDispatch();
    const { formButtonLoading } = useSelector((state) => state.loading)

    const submit = (formValues) => {
        const processed_remark = formValues.processed_remark;
        onClick({ processed_remark })
    };

    const methods = useForm({ defaultValues, mode: 'all' });

    return (
        <Form
            methods={methods}
            onSubmit={submit}
            submitButtonText={'Submit'}
            onCancel={() => dispatch(closeModalAction())}
            showSubmit
            submitContainerClassName='flex gap-2 justify-end py-4 px-4'
            isLoading={formButtonLoading[formName]}
        >
            <div className='flex gap-2 px-2'>
                <TextArea className='w-full' label='Remark' name='processed_remark' type={'text'} required />
            </div>
        </Form>
    );
}
