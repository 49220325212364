import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import { guid, validatePAN } from '../../../utils/utils';
import BankCard from '../../../common/BankCard';
import MiniPopup from '../../../common/MiniPopup';
import { BankForm } from '../../Payments/Modals/PayPO/BankForm';
import { getLoginOTP } from '../../../actions/auth';
import { getWalletDetailsById, withdrawWalletAmount } from '../../../actions/wallet';
import { closeModalAction } from '../../../actions/modal';
import { wallet as walletTypes } from '../../../types';
import ButtonScogoPrimary from '../../../common/Buttons/ButtonScogoPrimary';
import Form from '../../../common/Form';
import Input from '../../../common/Form/Input';
import { useForm } from 'react-hook-form';
import { convertToLakhOrCrore, validateNumberValue, validateStringValue } from '../../../utils/common';
import { isPayPoAllowed } from '../../../utils/access';
import { isCustomerGroup, isScm } from '../../../utils/role';
import { getBanksList, updateUserBankDetailsAndPay } from '../../../actions/users';
import { bank, upi } from '../../Payments/Modals/EditPayout/TicketBankTransferPayment';


export default function SendToBank({ transactionStatusQuery, defaultPage, defaultLimit, isShowDailyLimit, bankDetails, isBankDetailsLoading, wallet, totalWithdrawn, isUserSpPspSpisp, isUserIsIsp, directBankTransfer = false, vendorId, type, showTotalWithdrawn = true, userId, showUpiTransefer, selectedUser, ticketId, amountValidationCheck = () => { }, scogoBankAccountBalance, paymentType = bank, setPaymentType = () => { }, ticket_allowed_amount, showUserWalletBalance = false }) {

    const dispatch = useDispatch();
    const { loggedUser } = useSelector((state) => state.auth);
    const { isWithdrawAmountConfirmed } = useSelector((state) => state.wallet);
    const { formButtonLoading } = useSelector((state) => state.loading);
    const formName = 'sendToBank';
    const defaultValues = {
        amount: '',
        otp: '',
        name_of_account_holder: selectedUser ? selectedUser.name : ''
    };

    useEffect(() => {
        if (isCustomerGroup(loggedUser.role.id, loggedUser.type)) {
            setPaymentType(upi)
        }
    }, [setPaymentType, loggedUser.role.id, loggedUser.type]);

    useEffect(() => {
        return () => dispatch({ type: walletTypes.SET_IS_WITHDRAWAL_AMOUNT_CONFIRMED, payload: false });
    }, [dispatch]);

    const handleFormSubmit = (formValues) => {
        if (paymentType === upi && (!bankDetails?.upi_id)) {
            dispatch(
                updateUserBankDetailsAndPay({
                    data: {
                        name_of_account_holder: formValues.name_of_account_holder,
                        upi_user_id: userId,
                        upi_id: formValues.upi_id
                    },
                    id: userId,
                    formName,
                    source: 'add_upi_id_and_pay',
                    ticketId,
                    withdrawAmount: parseInt(formValues.amount),
                    remark: validateStringValue(formValues.remark)
                })
            );
            return;
        }

        if (directBankTransfer || (isWithdrawAmountConfirmed && formValues.otp) || (paymentType === upi && bankDetails?.upi_id)) {
            let data = {
                type: 'WITHDRAW',
                withdrawAmount: parseInt(formValues.amount),
                paymentMode: paymentType === upi ? 'UPI' : 'IMPS'
            };
            if (formValues.otp) {
                data.otp = parseInt(formValues.otp)
            }
            if (formValues.email) {
                data.email = formValues.email;
            }

            if (formValues.pancard_number) {
                data.pancard_number = formValues.pancard_number;
            }

            if (directBankTransfer) {
                data.banking_partner = 'BANKOPEN';
                data.recepient = userId;
            }

            if (ticketId) data.ticketId = ticketId;
            if (paymentType === upi) delete data.banking_partner;
            if (formValues.remark) data.remark = formValues.remark;

            dispatch(
                withdrawWalletAmount({ data, refresh: !directBankTransfer, formName, status: transactionStatusQuery, limit: defaultLimit, page: defaultPage, closeModal: directBankTransfer, ticketId })
            );

        } else {
            dispatch(
                getLoginOTP({
                    mobile: loggedUser.mobile,
                    skip: true,
                    bank_otp: 1,
                    amount: formValues.amount,
                    confirmWithdrawAmountOnSuccess: true,
                    formName,
                })
            );
        }
    };

    const sendToBankAmountCustomValidation = (amount) => {
        return parseFloat(amount) >= 400 && amount <= wallet?.wallet_amount;
    };

    const otpCustomValidation = (otp) => {
        return otp && otp.toString().length === 4;
    };

    const isUsrHaveEmail = loggedUser.email;
    const isUsrHavePancard = loggedUser.pancard_number;

    const methods = useForm({ defaultValues, mode: 'all' });
    const { watch } = methods;


    let isSubmitDisabled = parseInt(watch("amount")) > wallet?.withdraw_limit;
    if (directBankTransfer) {
        isSubmitDisabled = (paymentType === upi ? wallet?.scogo_zwitch_account_balance : scogoBankAccountBalance) < 400;
    }

    return (
        <>
            <div className='px-6 pt-4 '>
                <div className='flex gap-2'>
                    {isCustomerGroup(loggedUser.role.id, loggedUser.type) && ticketId ? <div className='bg-tagbg py-2 text-font11 px-2 rounded-lg '>Amount: ₹ {totalWithdrawn ? convertToLakhOrCrore(totalWithdrawn) : 0}</div> : <></>}
                    {(![undefined, false, null].includes(ticket_allowed_amount) && ticketId) ? < div className='bg-tagbg py-2 text-font11 px-2 rounded-lg '>User Ticket Amount: ₹ {ticket_allowed_amount}</div> : <></>}
                    {showUserWalletBalance ? <div className='bg-tagbg py-2 text-font11 px-2 rounded-lg '>User Wallet Balance: ₹ {wallet?.wallet_amount}</div> : <></>}
                    {(showTotalWithdrawn && !isCustomerGroup(loggedUser.role.id, loggedUser.type)) ? <div className='bg-tagbg py-2 text-font11 px-2 rounded-lg '>Total Withdrawn Amount: ₹ {totalWithdrawn ? convertToLakhOrCrore(totalWithdrawn) : 0}</div> : <></>}
                    {directBankTransfer ? <ScogoAmountBalance beneficiaryId={userId} isUpi={paymentType === upi} wallet={wallet} /> : <></>}
                </div>
                {showUpiTransefer && !isCustomerGroup(loggedUser.role.id, loggedUser.type) && <RenderOption setPaymentType={setPaymentType} paymentType={paymentType} />}

            </div >
            <Form
                methods={methods}
                onSubmit={handleFormSubmit}
                submitButtonText={directBankTransfer ? 'Transfer' : isWithdrawAmountConfirmed ? 'Withdraw' : 'Request OTP'}
                onCancel={() => dispatch(closeModalAction())}
                showSubmit
                submitContainerClassName='flex gap-2 justify-end py-4 px-4'
                isLoading={formButtonLoading[formName]}
                isSubmitDisabled={isSubmitDisabled}
            >
                <div className='px-3 pb-2'>
                    <RenderBankDetails isUserSpPspSpisp={isUserSpPspSpisp} isUserIsIsp={isUserIsIsp} bankDetails={bankDetails} vendorId={vendorId} type={type} isBankLoading={isBankDetailsLoading} showUpiTransefer={showUpiTransefer} paymentType={paymentType} userId={userId} />
                </div>
                <div className='px-3 relative'>
                    {!isUsrHaveEmail && !directBankTransfer && (
                        <Input
                            className='w-full'
                            label='Email'
                            type={'text'}
                            name='email'
                            required
                            validate={(email) => {
                                if (email === '') return true;
                                return (
                                    email.match(
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ) || 'Invalid Email'
                                );
                            }}
                        />
                    )}
                    {!isUsrHavePancard && !directBankTransfer && (
                        <Input
                            className='w-full'
                            label='Pancard Number'
                            type={'text'}
                            name='pancard_number'
                            required
                            validate={(pan) => {
                                if (pan === '') return true;
                                return (validatePAN(pan) || 'Invalid Pancard');
                            }}
                        />
                    )}
                    <div className='flex'>
                        <Input
                            allowFloats
                            className='w-full'
                            label='Amount'
                            type={'number'}
                            name='amount'
                            placeholder={'Amount'}
                            required
                            readOnly={isWithdrawAmountConfirmed}
                            validate={(amount) => {
                                if (amount === '') return true;
                                if (directBankTransfer && amountValidationCheck) {
                                    return amountValidationCheck(amount, wallet?.wallet_amount);
                                }
                                return (sendToBankAmountCustomValidation(amount) || 'Amount should be more than 400 and less than your wallet balance.');
                            }}

                        />

                        {directBankTransfer && <Input
                            allowFloats
                            className='w-full'
                            label='Remark'
                            type={'text'}
                            name='remark'
                            required
                        />}
                    </div>

                    {isWithdrawAmountConfirmed && !directBankTransfer && (
                        <Input
                            className='w-full'
                            label='OTP'
                            type={'text'}
                            name='otp'
                            placeholder={'OTP'}
                            required
                            validate={(otp) => {
                                if (otp === '') return true;
                                return (otpCustomValidation(otp) || 'Invalid OTP');
                            }}
                        />
                    )}
                    {isShowDailyLimit && <p className='text-slate-700 text-font12 italic absolute -bottom-12 ml-4' > *Your daily withdrawal limit is ₹ {wallet?.withdraw_limit}</p>}

                </div>
            </Form >

        </>
    );
}

const SkeltonLoader = () => [1, 2, 3].map((item) => <Skeleton key={`skelton_${guid()}`} animation='wave' height={20} width={250} />);

export const ScogoAmountBalance = ({ beneficiaryId, isUpi, wallet }) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser.role.id;
    const type = loggedUser.type;
    const frontend_controller = loggedUser.frontend_controller;
    let { walletDetailsById } = useSelector((state) => state.wallet);
    if (wallet) walletDetailsById = wallet;
    const { isWalletDetailsByIdLoading } = useSelector((state) => state.loading);
    const dispatch = useDispatch();
    const onRefreshClick = () => {
        dispatch(getWalletDetailsById({ updateBalance: false, beneficiary: beneficiaryId, accountBalance: 'BANKOPEN' }));
    };

    if ((isScm(role, type) && isPayPoAllowed(frontend_controller) || beneficiaryId) && !isCustomerGroup(role, type)) {
        let scogoBankAccountBalance = validateNumberValue(walletDetailsById?.scogo_bankopen_account_balance);
        if (isUpi) scogoBankAccountBalance = validateNumberValue(walletDetailsById?.scogo_zwitch_account_balance);
        return <div className='bg-tagbg py-2 text-font11 px-2 rounded-lg flex'>Scogo Balance: ₹ {scogoBankAccountBalance}
            <span className={`material-icons text-font11 bg-transparent hover:text-scogoorange cursor-pointer max-w-max border-2 border-tagbg rounded-full ${isWalletDetailsByIdLoading ? 'animate-spin' : ''}`} onClick={onRefreshClick}>refresh</span>
        </div>
    }

    if (isCustomerGroup(role, type) && loggedUser.wallet_detail) {
        return <div className='bg-tagbg py-2 text-font11 px-2 rounded-lg flex'>Balance: ₹ {loggedUser.wallet_detail.wallet_amount}
        </div>
    }

    return <></>;
}


export const RenderBankDetails = ({ isUserSpPspSpisp, isUserIsIsp, bankDetails, vendorId, type, isBankLoading, paymentType, userId }) => {
    const { banks } = useSelector((state) => state.user);
    const { isBankDetailsLoading: loggedUserBankDetailsLoading } = useSelector((state) => state.loading);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!banks) {
            dispatch(getBanksList());
        }
    }, [banks, dispatch]);

    const getBankDetailsCard = () => {
        let bankAccountNumber = bankDetails?.sp_bank_account_number || bankDetails?.bank_account_number;
        let bank_name = bankDetails?.sp_bank_name || bankDetails?.bank_name;
        let bank_ifsc_code = bankDetails?.sp_bank_ifsc_code || bankDetails?.bank_ifsc_code;
        let bank_account_holder_name = bankDetails?.sp_bank_account_holder_name || bankDetails?.bank_account_holder_name;

        if (isBankLoading || loggedUserBankDetailsLoading)
            return (
                <div className='w-full'>
                    <SkeltonLoader />
                </div>
            );
        else if (bankAccountNumber && bank_ifsc_code)
            return (
                <BankCard
                    bankName={bank_name}
                    ifscCode={bank_ifsc_code}
                    bankAccountHolderName={bank_account_holder_name}
                    bankAccountNumber={bankAccountNumber}
                />
            );

        const bankFormPopup = (
            <MiniPopup
                handleClick={() => { }}
                popup={
                    <BankForm
                        banks={banks}
                        callPay={false}
                        vendorId={vendorId}
                        isSP={isUserSpPspSpisp || isUserIsIsp}
                        isRefresh={true}
                        type={type}
                        userId={userId}
                    />
                }
                transition={false}
                className='fixed px-3 py-2 mt-2 z-10 rounded-md shadow-lg bg-white overflow-scroll left-0 top-0 border-2'
                menuStyle={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
            >
                <ButtonScogoPrimary textOrComponent={'Add Bank Details'} />
            </MiniPopup>
        );

        return (
            <div className='px-4 border-b-2 border-scogobgsky max-h-max py-2 mt-2 mx-2 rounded-lg gap-2'>
                <p className='text-font14 font-medium'>Bank Details Not Found</p>
                {bankFormPopup}
            </div>
        );
    };

    if (paymentType === upi) {
        return <RenderUpiCard upiId={bankDetails?.upi_id} vendorId={vendorId} />
    }

    if (paymentType === bank) {
        return <>
            <div className='pt-6 px-2'>
                <p className='text-font13 text-scogo18 font-medium md:mr-0 mr-2'>Amount will be credited to the following bank details</p>
            </div>
            <div>{getBankDetailsCard()}</div>
        </>
    }
    return <></>
};


const RenderOption = ({ setPaymentType, paymentType }) => {
    return <div className="flex gap-3 font-sans mt-4">
        <label className="flex items-center text-lg text-gray-800">
            <input
                type="radio"
                value={bank}
                checked={paymentType === bank}
                onChange={() => setPaymentType(bank)}
                className="hidden"
            />
            <span
                className={`w-5 h-5 border-2 rounded-full mr-3 flex items-center justify-center ${paymentType === bank ? 'border-orange-500' : 'border-gray-800'
                    }`}
            >
                {paymentType === bank && (
                    <span className="w-3 h-3 bg-scogoorange rounded-full"></span>
                )}
            </span>
            IMPS
        </label>
        <label className="flex items-center text-lg text-gray-800">
            <input
                type="radio"
                value={upi}
                checked={paymentType === upi}
                onChange={() => setPaymentType(upi)}
                className="hidden"
            />
            <span
                className={`w-5 h-5 border-2 rounded-full mr-3 flex items-center justify-center ${paymentType === upi ? 'border-orange-500' : 'border-gray-800'
                    }`}
            >
                {paymentType === upi && (
                    <span className="w-3 h-3 bg-scogoorange rounded-full"></span>
                )}
            </span>
            UPI
        </label>
    </div>
}


const RenderUpiCard = ({ upiId, vendorId }) => {

    return <>
        <div className='pt-6 px-2'>
            <p className='text-font13 text-scogo18 font-medium md:mr-0 mr-2'>Amount will be credited to the following UPI Id :
                &nbsp;  <span className='text-scogoToastSuccess'>{upiId ? upiId : ''}</span>
            </p>
        </div>
        {/* {upiId && <div className=' border-b-2 border-scogobgsky h-28 mt-2 rounded-lg'>
            <div className='w-full h-full px-4 flex flex-col justify-center border-2 rounded-lg'>
                {<p className=' text-font13 text-scogo15 font-bold md:mr-0 mr-2'>
                    <span className='text-scogoprimary font-medium'>UPI Id: </span>{upiId}
                </p>}
            </div>
        </div>} */}
        {
            !upiId && <div className='rows-fr grid-flow-row-dense gap-4 relative overflow-hidden'>
                <div className='relative flex'>
                    <Input
                        className='w-full'
                        label='Name'
                        type={'text'}
                        name='name_of_account_holder'
                        required
                    />
                    <Input
                        allowFloats
                        className='w-full'
                        label='UPI Id'
                        type={'text'}
                        name='upi_id'
                        required
                    />
                </div>
            </div>
        }
    </>
}



