import { useSelector } from 'react-redux';
import { getLookupWitHDefaultValue, actualValueForDropdown, validateStringValue, getIsNotScogoPartner, isAffiliateLinkGenerated } from '../../utils/common';
import { getCustomerIdOfLoginUser, encryptProfilePath } from '../../utils/utils';
import UpdateVendorsDetails from './Modals/UpdateDetails';
import PromoteUserModal from './Modals/PromoteUser';
import DemoteUserModal from './Modals/DemoteUser';
import DownloadReport from '../Modals/Inventory/DownloadReport';
import UploadFile from '../Modals/Inventory/UploadFile';
import { openCustomModal, openTextModalAction, closeModalAction } from '../../actions/modal';
import { updateUserStatus, updateTechSupportStatusOfUser, reInviteFE, bulkUploadISPResponses } from '../../actions/users';
import { copyToClipboard } from '../../actions/utils';
import { GenerateAffiliateLinkAction, deletePartner, giveOrRemoveHelpAccess, sendAndroidAppLink } from '../../actions/partners';
import { deleteFEUser } from '../../actions/fe';
import { deleteTeamAction } from '../../actions/superdesk';

import AddToTeam from './Modals/AddTeamInUsers';
import AddProjectInTeams from './Modals/AddProjectInTeams';
import EditTeamDetails from './Modals/EditTeam';
import UpdateTerritories from './Modals/UpdateTerritories';
import ShareFeasibilityLink from './Modals/ShareFeasibilityLink';
import AcceptsTickets from './Modals/AcceptsTickets';
import ISPCloseFeasibility from './Modals/ISPCloseFeasibility';
import DownloadISPFeasibilityReport from './Modals/DownloadISPFeasibilityReport';

import { isDirectBankTransferAllowed, isDownloadStatementAllowed, isUserChangesAllowed, isVerifyUserAllowed } from '../../utils/access';
import PartnerProfile from '../profile/PartnerProfile';
import { IdCard } from '../profile/IdCard';
import PayFe from './Modals/PayFe';
import { isScm, isCluster, isCustomer, isCustomerAdmin, isCustomerUser, isFe, isSp, isPsp, isIsp, isEndUser, isSpIsp, isSuperVisor, isCustomerGroup, isCsp, isIwan } from '../../utils/role';
import AddNewFieldEngineers from './Modals/AddNewFieldEngineers';
import AddCities from './Modals/AddCities';
import RequestStatement from '../Wallet/Modals/RequestStatement';
import { spareAssets as spareAssetsTab } from '../Inventory/InventoryController';
import DisableUser from './Modals/DisableUser';
import VerifyUserModal from './Modals/VerifyUserModal';
import VerificationPending from './Modals/VerificationPendingModal';
import { isHelpAllowed } from '../../utils/access';
import { BankTransfer } from './Modals/BankTransfer';

const users = 'users',
    teams = 'teams',
    vendors = 'vendors',
    sp = 'sp',
    isp = 'isp',
    csp = 'csp',
    fe = 'fe',
    vp = 'vp',
    scm = 'scm',
    cluster_psp = 'cluster_psp',
    clusterorPspFE = 'clusterorPspFE',
    iwan = 'iwan';

// Customer Team Module Tab
export const usersTab = { tabName: 'Users', tabKey: users, tabIcon: 'people_alt', iconSize: 'text-3xl', tabId: 'users', path: 'users' };
export const teamsTab = { tabName: 'Teams', tabKey: teams, tabIcon: 'groups', iconSize: 'text-3xl', tabId: 'teams', path: 'teams' };
export const vendorsTab = { tabName: 'Vendors', tabKey: vendors, tabIcon: 'people_alt', iconSize: 'text-3xl', tabId: 'customerVendors', path: 'customerVendors' };

// SCM Team Module Tab
export const allUserTab = { tabName: 'All', tabKey: sp, iconSize: 'text-3xl', tabId: 'all_users', path: 'all_users', showCount: true };
export const spTab = { tabName: 'Vendors', tabKey: sp, iconSize: 'text-3xl', tabId: 'vendors', path: 'vendors', showCount: true };
export const ispTab = { tabName: 'ISP', tabKey: isp, iconSize: 'text-3xl', tabId: 'isp', path: 'isp', showCount: true };
export const iwanTab = { tabName: 'Iwan', tabKey: iwan, iconSize: 'text-3xl', tabId: 'iwan', path: 'iwan', showCount: true };
export const cspTab = { tabName: 'CSP', tabKey: csp, iconSize: 'text-3xl', tabId: 'csp', path: 'csp', showCount: true };
export const feTab = { tabName: 'FE', tabKey: fe, iconSize: 'text-3xl', tabId: 'fe', path: 'fe', showCount: true };
export const vpTab = { tabName: 'Verification Pending', tabKey: vp, iconSize: 'text-3xl', tabId: 'vp', path: 'vp', showCount: true };
export const scmTab = { tabName: 'SCM', tabKey: scm, iconSize: 'text-3xl', tabId: 'scm', path: 'scm', showCount: true };
export const clusterOrPSPTab = { tabName: 'Cluster & PSP', tabKey: cluster_psp, iconSize: 'text-3xl', tabId: 'cluster_psp', path: 'cluster_psp', showCount: true };
export const fieldEngineersTab = { tabName: 'FIELD ENGINEERS', tabKey: clusterorPspFE, iconSize: 'text-3xl', tabId: 'field_engineer', path: 'field_engineer' };

// Customer -> Team -> Teams Tabs
const allTeamTab = { tabName: `All`, key: 'all', tabIcon: '', apiCallType: 'ALL' };
const myCustomerTab = { tabName: `My Customer`, key: 'customer', apiCallType: 'CUSTOMER', tabIcon: 'account_box' };
const myHelpdeskTab = { tabName: `My Help Desk`, key: 'helpsesk', apiCallType: 'HELPDESK', tabIcon: 'support_agent' };
const internalTab = { tabName: `Scogo`, key: 'internal', apiCallType: 'INTERNAL', tabIcon: 'groups' };
const scmCustomer = { tabName: `Customer`, key: 'scmCustomer', showDropdown: true, tabIcon: 'account_box', customer: true, apiCallType: 'SUPPORT' };

export const possibleStatusOfUsers = ['Active', 'Profile Pending', 'Bank Details Pending', 'Verification Pending', 'InActive', 'Disabled'];
export const possibleClusterAndPsp = ['CLUSTER', 'PSP', 'Supervisor'];
export const possibleInventoryStatus = ['Available', 'In Transit', 'Delivered'];

export const ScmPartnerListAPIQueryParams = ({ tabName }) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const role = loggedUser?.role?.id;
    const type = loggedUser.type;
    let stateIds;
    if (Array.isArray(loggedUser.applicable_territory)) {
        stateIds = loggedUser.applicable_territory.map((territory) => territory?.state_id).filter((id) => id);
    }
    const lookpup = {
        [spTab.path]: { role: 7, customer: 'scogo', userType: 'SP', stateIds },
        [ispTab.path]: { role: 7, customer: 'scogo', userType: 'ISP', stateIds },
        [cspTab.path]: { role: 13, customer: 'other', stateIds },
        [feTab.path]: { role: 8, stateIds },
        [vpTab.path]: { role: 'vp', stateIds },
        [scmTab.path]: { role: 6, userType: 'SCM', stateIds },
        [clusterOrPSPTab.path]: { role: 6, customer: 'scogo', userType: 'CLUSTER', stateIds },
        [iwanTab.path]: { role: 7, customer: 'scogo', userType: 'SP', fk_cluster_id: true, stateIds },
    };
    return lookpup[tabName];
};

const isPartnerDisabled = (partner) => {
    return partner?.is_enabled === 2;
};

const UpdateDetails = {
    key: 'update_details',
    menuName: 'Update Details',
    menuIcon: 'manage_accounts',
    iconColor: 'text-scogoorange',
    onClick: (userDetails, dispatch, { customerId, role, userType, customer, tabName, fk_cluster_id }) => {
        return dispatch(
            openCustomModal({
                heading: 'Update Details',
                modalWidth: '70rem',
                modalHeight: 'auto',
                modalComponent: (
                    <UpdateVendorsDetails
                        userDetails={userDetails}
                        role={role}
                        userType={userType}
                        customer={customer}
                        customerId={customerId}
                        tabName={tabName}
                        fk_cluster_id={fk_cluster_id}
                    />
                ),
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Update Details, User Is Disabled' };
    },
};

const acceptTickets = {
    key: 'accept_tickets',
    menuName: 'Accept Tickets',
    menuIcon: 'check_circle',
    iconColor: 'text-scogoToastSuccess',
    onClick: (userDetails, dispatch, { customerId, role, userType, customer, tabName, fk_cluster_id }) => {
        return dispatch(
            openCustomModal({
                heading: 'Accept Tickets',
                modalWidth: '80rem',
                modalHeight: 'auto',
                modalComponent: (
                    <AcceptsTickets
                        userDetails={userDetails}
                        role={role}
                        userType={userType}
                        customer={customer}
                        customerId={customerId}
                        tabName={tabName}
                        fk_cluster_id={fk_cluster_id}
                    />
                ),
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Accept Ticket, User Is Disabled' };
    },
};

const closeFeasibility = {
    key: 'close_feasibility',
    menuName: 'Close Feasibility',
    menuIcon: 'radar',
    iconColor: 'text-scogoToastSuccess',
    onClick: (userDetails, dispatch, { customerId, role, userType, customer, tabName }) => {
        const isp = {
            customerId: userDetails?.customer_detail?.customer_id,
            sp_id: userDetails?.sp_id,
            mobile: userDetails?.mobile,
            name: `${validateStringValue(userDetails.first_name)} ${validateStringValue(userDetails.last_name)}`,
            id: userDetails.id
        };
        return dispatch(
            openCustomModal({
                heading: 'Close Feasibility',
                modalWidth: '110rem',
                modalHeight: 'auto',
                modalComponent: <ISPCloseFeasibility userDetails={isp} role={role} userType={userType} customer={customer} customerId={customerId} tabName={tabName} />,
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'User Is Disabled' };
    },
};

const promoteUser = {
    key: 'promote_user',
    menuName: 'Promote User',
    menuIcon: 'trending_up',
    onClick: (userDetails, dispatch, { customerId, role, userType, customer }) => {
        return dispatch(
            openCustomModal({
                heading: 'Promote',
                modalWidth: '80rem',
                modalHeight: 'auto',
                modalComponent: <PromoteUserModal userDetails={userDetails} role={role} userType={userType} customer={customer} />,
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Promote, User Is Disabled' };
    },
};

const demoteUser = {
    key: 'demote_user',
    menuName: 'Demote User',
    menuIcon: 'trending_down',
    onClick: (userDetails, dispatch, { customerId, role, userType, customer }) => {
        return dispatch(
            openCustomModal({
                heading: 'Demote',
                modalWidth: '80rem',
                modalHeight: 'auto',
                modalComponent: <DemoteUserModal userDetails={userDetails} role={role} userType={userType} customer={customer} />,
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Demote, User Is Disabled' };
    },
};

const enableUser = {
    key: 'enable_user',
    menuName: 'Enable user',
    menuIcon: 'visibility',
    iconColor: 'text-scogoToastSuccess',
    onClick: (partner, dispatch, { role, userType, customer, fk_cluster_id }) => {
        const userName = `${validateStringValue(partner?.first_name)} ${validateStringValue(partner?.last_name)}`;

        return dispatch(
            openTextModalAction({
                heading: 'Enable',
                text: `Are you sure you want to Enable ${userName}`,
                closeOnBackdropClick: false,
                formName: 'enableUser',
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'blue',
                        onClick: () => {
                            dispatch(updateUserStatus({ userId: partner.id, status: 'enable', role, userType, customer, formName: 'enableUser', userName, fk_cluster_id }));
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'redOutlined',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
};

const disableUser = {
    key: 'disable_user',
    menuName: 'Disable User',
    menuIcon: 'visibility_off',
    onClick: (partner, dispatch, { role, userType, customer, fk_cluster_id }) => {
        return dispatch(
            openCustomModal({
                heading: 'Disable User',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <DisableUser partner={partner} role={role} userType={userType} customer={customer} fk_cluster_id={fk_cluster_id} />,
            })
        );
    },
};

const enableDisableTechSupport = {
    key: 'enable_disable_tech_support',
    formName: 'enableTechSupport',
    conditionalRendering: (userDetails) => {
        if (userDetails.is_possible_tech_support === 0) {
            return { menuName: 'Enable Tech Support', menuIcon: 'headset', iconColor: 'text-scogoToastSuccess' };
        }
        return { menuName: 'Disable Tech Support', menuIcon: 'headset_off', iconColor: 'text-scogoclosed' };
    },
    onClick: (userDetails, dispatch, { customerId, role, userType, customer, fk_cluster_id, userId }) => {

        const userName = `${validateStringValue(userDetails?.first_name)} ${validateStringValue(userDetails?.last_name)}`;
        let formName = 'disableTechSupprt';
        let is_possible_tech_support = 0;
        let heading = 'Disable Tech Support';
        let text = `Are you sure you want to Disable Tech Support ${userName}`;
        let confirmButtonType = 'red';
        let cancelButtontype = 'blue';

        if (userDetails.is_possible_tech_support === 0) {
            formName = 'enableTechSupport';
            heading = 'Enable Tech Support';
            text = `Are you sure you want to Enable Tech Support ${userName}`;
            confirmButtonType = 'blue';
            cancelButtontype = 'redOutlined';
            is_possible_tech_support = 1;
        }

        return dispatch(
            openTextModalAction({
                closeOnBackdropClick: false,
                formName,
                heading,
                text,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: confirmButtonType,
                        onClick: () => {
                            dispatch(
                                updateTechSupportStatusOfUser({
                                    userId: userId ? userId : userDetails.id,
                                    is_possible_tech_support,
                                    role,
                                    userType,
                                    customer,
                                    formName,
                                    fk_cluster_id,
                                })
                            );
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: cancelButtontype,
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Enable Tech Support, User Is Disabled' };
    },
};

const giveRemoveHelpAccess = {
    key: 'give_remove_help_access',
    formName: 'givRemoveHelpAccess',
    conditionalRendering: (userDetails) => {
        const isHelpAccess = userDetails?.frontend_controller && userDetails?.frontend_controller?.split(",")?.find((elem) => elem === 'HelpAccess');
        if (isHelpAccess) {
            return { menuName: 'Remove Help Access', menuIcon: 'help', iconColor: 'text-scogoclosed' };
        }
        return { menuName: 'Give Help Access', menuIcon: 'help', iconColor: 'text-scogo2e' };
    },
    onClick: (userDetails, dispatch, { customerId, role, userType, customer, fk_cluster_id }) => {
        const isHelpAccess = userDetails?.frontend_controller && userDetails.frontend_controller?.split(",")?.find((elem) => elem === 'HelpAccess');
        const userName = `${validateStringValue(userDetails?.first_name)} ${validateStringValue(userDetails?.last_name)}`;
        let formName = 'giveHelpAccess';
        let heading = 'Give Help Access';
        let text = `Are You Sure You Want To Give Help Access To ${userName}`;
        let confirmButtonType = 'blue';
        let cancelButtontype = 'redOutlined';
        let access = true;

        if (isHelpAccess) {
            formName = 'removeHelpAccess';
            heading = 'Remove Help Access';
            text = `Are You Sure You Want To Remove Help Access To ${userName}`;
            confirmButtonType = 'red';
            cancelButtontype = 'blue';
            access = false;
        }

        return dispatch(
            openTextModalAction({
                closeOnBackdropClick: false,
                formName,
                heading,
                text,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: confirmButtonType,
                        onClick: () => {
                            dispatch(giveOrRemoveHelpAccess({ userId: userDetails.id, access, formName, role, customerName: customer, userType, fk_cluster_id }))
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: cancelButtontype,
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Enable Tech Support, User Is Disabled' };
    },
};

const deleteTeamVendor = {
    key: 'delete_team_vendor',
    menuName: 'Delete',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (userDetails, dispatch, { customerId, role, userType, customer, tabName, fk_cluster_id }) => {
        const userName = `${validateStringValue(userDetails?.first_name)} ${validateStringValue(userDetails?.last_name)}`;
        const userId = userDetails?.fk_user_id ? userDetails?.fk_user_id : userDetails?.id;
        return dispatch(
            openTextModalAction({
                heading: 'Delete',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName: 'deleteVendor',
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        onClick: () => {
                            dispatch(
                                deletePartner({
                                    userId,
                                    role,
                                    userType,
                                    customer,
                                    tabName,
                                    customerId,
                                    formName: 'deleteVendor',
                                    userName,
                                    fk_cluster_id,
                                })
                            );
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Delete, User Is Disabled' };
    },
};

const deleteFeAction = {
    key: 'delete_team_vendor',
    menuName: 'Delete',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    onClick: (userDetails, dispatch) => {
        const userName = `${validateStringValue(userDetails?.user?.first_name)} ${validateStringValue(userDetails?.user?.last_name)}`;
        const payload = { data: { spId: userDetails.fk_sp_id, feId: userDetails.field_engineer_id }, formName: 'deleteFe', userName };
        return dispatch(
            openTextModalAction({
                heading: 'Delete',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName: 'deleteFe',
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        buttonClass: 'hover:bg-scogoLightFillRed',
                        onClick: () => {
                            dispatch(deleteFEUser(payload));
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
};

const reInviteFe = {
    key: 'reinvite_fe',
    menuName: 'Re Invite',
    menuIcon: 'reply',
    iconColor: 'text-scogoprimary',
    onClick: (userDetails, dispatch) => {
        const formName = 'reinvite_fe';
        const toastMsg = `${validateStringValue(userDetails?.user?.first_name)} ${validateStringValue(userDetails?.user?.last_name)} Re-Invited successfully`;

        const payload = { feId: userDetails?.fk_user_id, formName, toastMsg };

        return dispatch(
            openTextModalAction({
                heading: 'Re Invite',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName,
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(reInviteFE(payload));
                        },
                    },
                    {
                        textOrComponent: 'Cancel',
                        type: 'redOutlined',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
};

const updateTerritories = {
    key: 'update_territories',
    menuName: 'Update Territories',
    menuIcon: 'place',
    onClick: (userDetails, dispatch, { customerId, role, userType, customer }) => {
        return dispatch(
            openCustomModal({
                heading: 'Update Territories',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <UpdateTerritories userDetails={userDetails} role={role} userType={userType} customer={customer} />,
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Update Territories, User Is Disabled' };
    },
};

const shareFeasibilityLink = {
    key: 'share_feasibility_link',
    menuName: 'Share Feasibility Link',
    menuIcon: 'share',
    iconColor: 'text-scogoprimary',
    onClick: (userDetails, dispatch, { customerId }) => {
        const isps = [userDetails?.id];
        return dispatch(
            openCustomModal({
                heading: 'Share Feasibility Link',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <ShareFeasibilityLink userDetails={userDetails} isps={isps} />,
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Share Feasibility Link, User Is Disabled' };
    },
};

const copyFeasibilityLink = {
    key: 'copy_feasibility_link',
    menuName: 'Copy Feasibility Link',
    menuIcon: 'content_copy',
    onClick: (userDetails, dispatch) => {
        let forCopy = `${window.location.origin}/?isp_number=${userDetails?.mobile}&redirect_uri=${encodeURIComponent(`${window.location.origin}/feasibility`)}`;
        return dispatch(copyToClipboard({ data: forCopy }));
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Copy Feasibility Link, User Is Disabled' };
    },
};

const shareOnWhatsappLogic = ({ userDetails }) => {
    window.open(
        `https://api.whatsapp.com/send/?phone=91${userDetails.mobile}&text=${`Hi ${userDetails.first_name} ${userDetails.last_name
        }, Please submit the Feasibility by clicking on the link ${encodeURIComponent(
            `${window.location.origin}/?isp_number=${userDetails.mobile}&redirect_uri=${encodeURIComponent(`${window.location.origin}/feasibility`)}`
        )}`}&app_absent=0`,
        '_blank'
    );
};

const shareOnWhatsApp = {
    key: 'share_on_whatsapp',
    menuName: 'Share On WhatsApp',
    menuIcon: 'share',
    onClick: (userDetails, dispatch) => {
        return shareOnWhatsappLogic({ userDetails });
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Share, User Is Disabled' };
    },
};

const copyProfileLink = {
    key: 'copy_profile_link',
    menuName: 'Copy Profile URL',
    menuIcon: 'person',
    onClick: (userDetails, dispatch, { }) => {
        const firstName = userDetails?.first_name ? userDetails?.first_name : userDetails?.user?.first_name;
        const lastName = userDetails?.last_name ? userDetails?.last_name : userDetails?.user?.last_name;
        const userId = userDetails?.id ? userDetails?.id : userDetails?.user?.id;
        const userNameOrID = `${firstName ? firstName.replace(/\s+/g, '') : ''}${lastName ? lastName.replace(/\s+/g, '') : ''}-${userId}`;
        const profilePath = encryptProfilePath({ text: userNameOrID });
        let forCopy = `${window.location.origin}/profile/${profilePath}`;
        return dispatch(copyToClipboard({ data: forCopy }));
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Copy Profile URL, User Is Disabled' };
    },
};

const sendAppLink = {
    key: 'send_app_link',
    menuName: 'Send App Link',
    menuIcon: 'phone_iphone',
    onClick: (userDetails, dispatch, { }) => {
        return dispatch(sendAndroidAppLink({ mob_number: [userDetails?.mobile], msg_type: 'msg_app_link' }));
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Send App Link, User Is Disabled' };
    },
};

const viewProfile = {
    key: 'view_profile',
    menuName: 'View Profile',
    menuIcon: 'account_box',
    iconColor: 'text-scogoprimary',
    onClick: (userDetails, dispatch, { customerId }) => {
        const userId = userDetails?.id ? userDetails?.id : userDetails?.user?.id;
        const firstName = userDetails?.first_name ? userDetails?.first_name : userDetails?.user?.first_name;
        const lastName = userDetails?.last_name ? userDetails?.last_name : userDetails?.user?.last_name;
        const username = `${firstName ? firstName.replace(/\s+/g, '') : ''}${lastName ? lastName.replace(/\s+/g, '') : ''}`;
        return dispatch(
            openCustomModal({
                heading: 'Public Profile',
                modalWidth: '90rem',
                modalHeight: 'auto',
                modalComponent: <PartnerProfile userDetails={userDetails} userId={userId} username={username} copyProfileUrlButton={true} />,
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot View Profile, User Is Disabled' };
    },
};

const viewIDCard = {
    key: 'view_id_card',
    menuName: 'ID Card',
    menuIcon: 'assignment_ind',
    iconColor: 'text-scogoprimary',
    onClick: (userDetails, dispatch, { customerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'ID Card',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <IdCard userDetails={userDetails} />,
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot View ID Card, User Is Disabled' };
    },
};

const payFieldEngineers = {
    key: 'pay_field_engineers',
    menuName: 'Pay',
    menuIcon: 'currency_rupee',
    iconColor: 'text-scogoprimary',
    onClick: (userDetails, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Transfer Amount',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <PayFe feDetails={userDetails} />,
            })
        );
    },
};

const addToTeam = {
    menuName: 'Add to Team',
    menuIcon: 'groups',
    iconColor: 'text-scogoprimary',
    onClick: (userDetails, dispatch, { }) => {
        return dispatch(
            openCustomModal({
                heading: 'Add to Team',
                modalWidth: '55rem',
                modalHeight: 'auto',
                modalComponent: <AddToTeam agentDetails={userDetails} />,
            })
        );
    },
};

const deleteTeam = {
    menuName: 'Delete Team',
    menuIcon: 'delete',
    iconColor: 'text-scogoclosed',
    formName: 'deleteTeam',
    onClick: (item, dispatch, { }) => {
        return dispatch(
            openTextModalAction({
                heading: 'Delete Team',
                text: 'Are you sure you want to continue?',
                closeOnBackdropClick: false,
                formName: 'deleteTeam',
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        onClick: () => {
                            dispatch(deleteTeamAction({ teamId: item.id, teamName: item.name, formName: 'deleteTeam' }));
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
};

const addToProject = {
    menuName: 'Add To Project',
    menuIcon: 'list_alt',
    iconColor: 'text-black',
    onClick: (team, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Add To Project',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <AddProjectInTeams team={team} />,
            })
        );
    },
};

const editTeam = {
    menuName: 'Edit Team',
    menuIcon: 'edit',
    iconColor: 'text-black',
    onClick: (item, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Edit Team Name',
                modalWidth: '50rem',
                modalHeight: 'auto',
                modalComponent: <EditTeamDetails teamDetails={item} />,
            })
        );
    },
};

const linkedTeamProjects = {
    menuName: 'Linked Projects',
    menuIcon: 'article',
    iconColor: 'text-scogoorange',
    onClick: (item, dispatch, { onLinkedTeamProjectClick }) => {
        onLinkedTeamProjectClick(item)
    },
};

const addNewFieldEngineers = {
    menuName: 'Field Engineer',
    menuIcon: 'person_add',
    iconColor: 'text-scogoprimary',
    onClick: (item, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Add New Field Engineer',
                modalWidth: '85rem',
                modalHeight: 'auto',
                modalComponent: <AddNewFieldEngineers />,
            })
        );
    },
};

const addServiceLocationForFE = {
    menuName: 'Service Location',
    menuIcon: 'place',
    iconColor: 'text-scogoprimary',
    onClick: (item, dispatch, { servicePartnerId }) => {
        return dispatch(
            openCustomModal({
                heading: 'Add Service Locations',
                modalWidth: '40rem',
                modalHeight: 'auto',
                modalComponent: <AddCities servicePartnerId={servicePartnerId} />,
            })
        );
    },
};

export const goToUserDocuments = ({ document_folder_id, navigate }) => {
    return navigate(`/documents?path=/${document_folder_id}`);
};

const userDocuments = {
    key: 'user_documents',
    menuName: 'Documents',
    menuIcon: 'folder',
    onClick: (userDetails, _, { navigate }) => {
        let document_folder_id = '';
        if (userDetails.user) document_folder_id = userDetails.user.document_folder_id;
        else document_folder_id = userDetails.document_folder_id;
        return navigate(`/documents?path=/${document_folder_id}`);
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'User Is Disabled' };
    },
};

const downloadConsumableReport = {
    key: 'download_consumable_report',
    menuName: 'Download Consumable Report',
    menuIcon: 'file_download',
    onClick: (user, dispatch, { customerId, loggedUserRole, loggedUserEmail }) => {
        return dispatch(
            openCustomModal({
                heading: 'Download CSV',
                modalWidth: '44rem',
                modalHeight: 'auto',
                modalComponent: (
                    <DownloadReport
                        customerId={customerId}
                        role={loggedUserRole}
                        defaultEmail={loggedUserEmail}
                        tabName={spareAssetsTab.id}
                        warehouseId={user.warehouse_id}
                        count='ALL'
                        status='DELIVERED'
                    />
                ),
            })
        );
    }
};

const viewWarehouse = {
    key: 'view_warehouse',
    menuName: 'View Warehouse',
    menuIcon: 'warehouse',
    iconColor: 'text-scogoprimary',
    onClick: (user, __, { navigate }) => {
        return navigate(`/inventory?tab=spareAssets&warehouseId=${user.warehouse_id}`);
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot View Warehouse, User Is Disabled' };
    },
};

const bulkUploadResponses = {
    key: 'bulk_upload_responses',
    menuName: 'Bulk Upload Responses',
    menuIcon: 'cloud_upload',
    onClick: (user, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Bulk Upload Responses',
                modalWidth: '64rem',
                modalHeight: 'auto',
                modalComponent: (
                    <UploadFile
                        uploadFileAction={({ file, formName }) => bulkUploadISPResponses({ file, ispId: user.customer_detail.customer_id, formName, toCloseModal: true })}
                        filename={'bulk_isp_response'}
                    />
                ),
            })
        );
    },
    isDisabled: (partner) => {
        return { disabled: isPartnerDisabled(partner), message: 'Cannot Upload Responses, User Is Disabled' };
    },
};

const downloadStatement = {
    key: 'download_statement',
    menuName: 'Download Statement',
    menuIcon: 'currency_rupee',
    onClick: (user, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: `Download Statement | ${user.first_name} ${user.last_name}`,
                modalHeight: 'auto',
                modalWidth: '45rem',
                modalComponent: <RequestStatement userId={user.id} />,
            })
        );
    },
};

const downloadISPFeasibityReport = {
    key: 'download_feasibility_report',
    menuName: 'Feasibility Report',
    imgIcon: './img/csvDownload.png',
    onClick: (_, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: `Feasibility Report`,
                modalHeight: 'auto',
                modalWidth: '45rem',
                modalComponent: <DownloadISPFeasibilityReport />,
            })
        );
    },

};

const verfiyUserAddressProof = {
    menuName: 'Verify User',
    menuIcon: 'person',
    iconColor: 'text-scogoorange',
    key: 'verify_user_aadhar',
    onClick: (user, dispatch, { role, customer, userType, fk_cluster_id }) => {
        return dispatch(
            openCustomModal({
                heading: 'Verify User',
                modalWidth: '60rem',
                modalHeight: 'auto',
                modalComponent: <VerifyUserModal user={user} role={role} customer={customer} userType={userType} fk_cluster_id={fk_cluster_id} />,
            })
        );
    },
};

const verfiyUser = {
    menuName: 'Verify User',
    menuIcon: 'person',
    iconColor: 'text-scogoorange',
    key: 'verify_user',
    onClick: (user, dispatch) => {
        return dispatch(
            openCustomModal({
                heading: 'Verification',
                modalWidth: '65rem',
                modalHeight: 'auto',
                modalComponent: <VerificationPending userDetails={user} role={'vp'} />
            })
        );
    },
};

const directBankTrasfer = {
    menuName: 'Direct Bank Transfer',
    menuIcon: 'account_balance',
    iconColor: 'text-scogoorange',
    key: 'direct_bank_transfer',
    onClick: (user, dispatch) => {
        const userName = `${validateStringValue(user.first_name)} ${validateStringValue(user.last_name)}`
        return dispatch(
            openCustomModal({
                heading: ` Money Transfer From ${userName ? userName : validateStringValue(user.mobile)} Wallet To Bank`,
                modalWidth: '65rem',
                modalHeight: 'auto',
                modalComponent: <BankTransfer user={user} />
            })
        );
    },
};

const GenerateAffiliateLink = {
    menuName: 'Generate Affiliate Link',
    menuIcon: 'link',
    iconColor: 'text-scogoprimary',
    key: 'generate-affiliate-link',
    onClick: (user, dispatch) => {
        return dispatch(
            openTextModalAction({
                heading: 'Generate Affiliate Link',
                text: 'Are you sure you want to generate affiliate link?',
                closeOnBackdropClick: false,
                formName: 'generate-affiliate-link',
                buttons: [
                    {
                        showLoading: true,
                        textOrComponent: 'Yes',
                        type: 'red',
                        onClick: () => {
                            dispatch(GenerateAffiliateLinkAction({ userId: user?.id, formName: 'generate-affiliate-link', invokeFrom: 'teams' }));
                        },
                    },
                    {
                        textOrComponent: 'No',
                        type: 'blue',
                        buttonClass: 'hover:bg-scogobgsky',
                        onClick: () => {
                            dispatch(closeModalAction());
                        },
                    },
                ],
            })
        );
    },
}

export const GetTeamsTab = (logic) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const isFeasibilityEligible = loggedUser.is_feasibility_eligible;
    const roleId = loggedUser?.role?.id;
    const type = loggedUser.type;
    if (logic === 'tabs') return getTabList(roleId, type, isFeasibilityEligible);
    if (logic === 'teamsTab') return getTeamsTabList(roleId, type);
};

const getTabFromQueryString = (tabs, urlQueryString) => {
    const match = urlQueryString.match(/[\?&]tab=(\w+)/);
    let queryTab;
    if (match) queryTab = match[1];
    const matchingTab = tabs.find((tab) => tab.path === queryTab);
    if (matchingTab) return { matchingTab };
    return {};
};

export const getTeamsDefaultTab = (role, type, { urlQueryString }) => {
    const tabs = GetTeamsTab('tabs');
    let tab = tabs[0];
    if (urlQueryString) {
        const { matchingTab } = getTabFromQueryString(tabs, urlQueryString);
        if (matchingTab) {
            tab = matchingTab;
        }
    }
    return tab?.path;
};

const getTabList = (role, type, isFeasibilityEligible) => {
    let tabs = [];
    if (isScm(role, type)) tabs = [allUserTab, spTab, ispTab, iwanTab, cspTab, feTab, vpTab, clusterOrPSPTab, scmTab, teamsTab];
    else if (isCustomer(role, type)) {
        tabs = [usersTab, teamsTab, vendorsTab];
        if (isFeasibilityEligible === 1) tabs.push(ispTab);
    } else if (isCustomerAdmin(role, type) || isCustomerUser(role, type)) tabs = [usersTab, teamsTab, vendorsTab];
    else if (isSp(role, type)) tabs = [fieldEngineersTab];
    else if (isPsp(role, type) || isCluster(role, type) || isSuperVisor(role, type)) tabs = [spTab, clusterOrPSPTab, fieldEngineersTab];
    else if (isIsp(role, type)) tabs = [usersTab];
    return tabs;
};

const getTeamsTabList = (role, type) => {
    if (isScm(role, type)) return [internalTab, scmCustomer];
    else if (isCustomer(role, type)) return [allTeamTab, myCustomerTab, myHelpdeskTab];
    else if (isCustomerAdmin(role, type)) return [allTeamTab, myCustomerTab, myHelpdeskTab];
    else if (isCustomerUser(role, type)) return [allTeamTab, myCustomerTab, myHelpdeskTab];
    return [];
};

const getTeamBulkActions = (role, type, { activeTab }) => {
    let actions = [];
    if (isScm(role, type)) actions = [downloadISPFeasibityReport];
    actions = actions.filter((action) => {
        if (action.key === downloadISPFeasibityReport.key) {
            return activeTab === ispTab.tabKey;
        }
        return true;
    });
    return actions;
};

export const GetSendInviteLogic = ({ logic, selectedCustomerId, isSelectedCustomerFeasibilityEligible = 0 } = {}) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const roleId = loggedUser?.role?.id;
    const customerId = getCustomerIdOfLoginUser(loggedUser);
    const type = loggedUser.type;
    if (isCustomer(roleId, type)) isSelectedCustomerFeasibilityEligible = loggedUser.is_feasibility_eligible;
    if (logic === 'sendInvitesRadio') return handleRadioOptionsAccordingToLogin(roleId, type, selectedCustomerId, isSelectedCustomerFeasibilityEligible);
    if (logic === 'getCustomerIdSendInvites') return handleCustomerIdForScmCustomerTab(roleId, type, selectedCustomerId, customerId);
};

export const GetTeamsListLogic = (logic, { user, openTab } = {}) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const loggedUserId = loggedUser?.id;
    const roleId = loggedUser?.role?.id;
    const type = loggedUser.type,
        frontend_controller = loggedUser.frontend_controller;
    if (logic === 'teamActions') return getTeamActions(roleId, type);
    else if (logic === 'addUsersButton') return isAllowedAddUsersButton(roleId, type);
    else if (logic === 'removeUserFromTeamButton') return isAllowedRemoveUserButton(roleId, type);
    else if (logic === 'userActions') return getUsersActions(roleId, type, { userWarehouseId: user.warehouse_id });
    else if (logic === 'vendorsActions') return getVendorsActions(roleId, type, { partner: user });
    else if (logic === 'scmPartnerActions') return getSCMPartnersActions({ frontend_controller, partner: user, openTab, userWarehouseId: user.warehouse_id });
    else if (logic === 'spFieldEngineerAction') return getSpFieldEngineerAction({ partner: user, loggedUserId });
    else if (logic === 'fieldEngineerAddNewActions') return getFeAddNewActions(roleId, type);
    else if (logic === 'bulkActions') return getTeamBulkActions(roleId, type, { activeTab: openTab });
    else if (logic === 'isToShowVendorCode') return isCustomer(roleId, type);
};

const getTeamActions = (role, type) => {
    if (isScm(role, type) || isCustomer(role, type) || isCustomerAdmin(role, type)) return [editTeam, linkedTeamProjects, addToProject, deleteTeam];
    return [];
};

const getUsersActions = (role, type, { userWarehouseId }) => {
    let actions = [viewWarehouse, downloadConsumableReport];

    if (isCustomer(role, type)) actions = [userDocuments, addToTeam, enableDisableTechSupport, deleteTeamVendor];
    else if (isCustomerAdmin(role, type)) actions = [userDocuments, addToTeam, enableDisableTechSupport];
    actions = actions.filter((action) => {
        if ([viewWarehouse.key, downloadConsumableReport.key].includes(action.key)) {
            return userWarehouseId > 0;
        }
        return true;
    });
    return actions;
};

const getFeAddNewActions = (roleId, type) => {
    if (isCluster(roleId, type)) return [addNewFieldEngineers];
    return [addNewFieldEngineers, addServiceLocationForFE];
};

const getSpFieldEngineerAction = ({ partner, loggedUserId }) => {
    let actions = [reInviteFe, viewProfile, copyProfileLink, viewWarehouse, downloadConsumableReport, payFieldEngineers, deleteFeAction];

    actions = actions.filter((action) => {
        let key = action.key;
        if (key === 'view_profile' || key === 'copy_profile_link') {
            return partner.user.status === 3;
        } else if (key === 'reinvite_fe') {
            return partner.user.status === 0;
        } else if (key === 'pay_field_engineers') {
            return partner.user.status === 3 && partner.fk_user_id !== loggedUserId;
        } else if ([viewWarehouse.key, downloadConsumableReport.key].includes(key)) {
            return partner.user.warehouse_id > 0;
        }
        return true;
    });

    return actions;
};

const getVendorsActions = (role, type, { partner }) => {
    let actions = [viewWarehouse, downloadConsumableReport];
    if (isCustomer(role, type)) actions = actions.concat([userDocuments, UpdateDetails, deleteTeamVendor]);
    else if (isCustomerAdmin(role, type)) actions = actions.concat([userDocuments, UpdateDetails, deleteTeamVendor]);
    actions = actions.filter((action) => {
        if ([viewWarehouse.key, downloadConsumableReport.key].includes(action.key)) {
            return partner.user.warehouse_id > 0;
        }
        return true;
    });
    return actions;
};

const filterPartnerActions = ({ partner, actions }) => {
    const partnerRoleId = partner.role;
    const partnerType = partner.type;

    const feasibilityActions = ['close_feasibility', 'share_feasibility_link', 'copy_feasibility_link', 'share_on_whatsapp'];

    actions = actions.filter((action) => {
        const key = action.key;

        if (key === 'send_app_link') {
            return !isScm(partnerRoleId, partnerType) && partner.mobile && partner.download_app_status === 0;
        } else if (key === 'update_details') {
            return (
                isCustomer(partnerRoleId, partnerType) ||
                isIsp(partnerRoleId, partnerType) ||
                isFe(partnerRoleId, partnerType) ||
                isSp(partnerRoleId, partnerType) ||
                isPsp(partnerRoleId, partnerType) ||
                isSpIsp(partnerRoleId, partnerType)
            );
        } else if (feasibilityActions.includes(key)) {
            return isIsp(partnerRoleId, partnerType) || isSpIsp(partnerRoleId, partnerType);
        } else if (key === 'accept_tickets') {
            return isSp(partnerRoleId, partnerType) || isPsp(partnerRoleId, partnerType) || isSpIsp(partnerRoleId, partnerType);
        } else if (key === 'promote_user') {
            return !isCluster(partnerRoleId, partnerType) && !isSuperVisor(partnerRoleId, partnerType);
        } else if (key === demoteUser.key) {
            return isCluster(partnerRoleId, partnerType) || isPsp(partnerRoleId, partnerType) || isSuperVisor(partnerRoleId, partnerType) || isIwan(partnerRoleId, partnerType);
        } else if (key === 'delete_team_vendor') {
            return partner.status === 0 || ([1, 2, 3].includes(partner.status) && isFe(partnerRoleId, partnerType)) || [0, 1, 2, 2.5, 3].includes(partner.status);
        } else if (key === 'copy_profile_link' || key === 'view_id_card' || key === 'view_profile') {
            return (
                (partner.status === 2.5 || partner.status === 3) &&
                (isFe(partnerRoleId, partnerType) || isSp(partnerRoleId, partnerType) || isPsp(partnerRoleId, partnerType) || isSpIsp(partnerRoleId, partnerType))
            );
        }
        return true;
    });

    return actions;
};

const commonActionsForSCMTeamsTab = ({ partner, frontend_controller }) => {
    let commonActions = [verfiyUserAddressProof, verfiyUser, enableUser, disableUser, enableDisableTechSupport, userDocuments, viewWarehouse, downloadConsumableReport, downloadStatement, directBankTrasfer, GenerateAffiliateLink];
    commonActions = commonActions.filter((action) => {
        if (action.key === 'disable_user') {
            return !isPartnerDisabled(partner);
        } else if (action.key === 'enable_user') {
            return isPartnerDisabled(partner);
        } else if (action.key === directBankTrasfer.key) {
            return isDirectBankTransferAllowed(frontend_controller) && !isCsp(partner.role, partner.type, partner.partner_working_under);
        }

        return true;
    });
    return commonActions;
};

export const getIsToShowAddressVerifyUser = ({ loggedUserFrontendController, partner }) => {
    const { role, type, status, address_proof_verified, partner_working_under } = partner;
    const isNotScogoPartner = isSp(role, type) && partner_working_under && partner_working_under !== 'SCOGO';
    if (isSp(role, type)) {
        return isVerifyUserAllowed(loggedUserFrontendController) && (status === 3 && address_proof_verified === 0) && !isCustomerGroup(role, type) && !isNotScogoPartner
    }
    return isVerifyUserAllowed(loggedUserFrontendController) && (address_proof_verified === 0) && !isCustomerGroup(role, type) && !isNotScogoPartner && status !== 0
}

export const getIsToShowVerificationPendingModal = ({ loggedUserFrontendController, partner }) => {
    const { status, role, type } = partner;
    return isVerifyUserAllowed(loggedUserFrontendController) && isSp(role, type) && ![0, 3].includes(status);
}

const getSCMPartnersActions = ({ frontend_controller, partner, openTab, userWarehouseId }) => {
    const user_change_access_action_keys = ['enable_user', 'disable_user', 'promote_user', 'demote_user', 'update_territories', 'delete_team_vendor', 'generate-affiliate-link'];

    let actions = [];

    let commonActions = commonActionsForSCMTeamsTab({ partner, frontend_controller });
    let possibleActions = scmPartnerActionLookup[openTab]({ partner });

    actions = [...commonActions, ...possibleActions];

    actions = filterPartnerActions({ partner, frontend_controller, actions });

    if (openTab === scmTab.path && isUserChangesAllowed(frontend_controller)) {
        actions.unshift(giveRemoveHelpAccess)
    }

    actions = actions.filter((action) => {
        if (action.key === GenerateAffiliateLink.key) {
            return !isAffiliateLinkGenerated(partner) && isUserChangesAllowed(frontend_controller);
        }
        if (user_change_access_action_keys.includes(action.key)) {
            return isUserChangesAllowed(frontend_controller);
        } else if ([viewWarehouse.key, downloadConsumableReport.key].includes(action.key)) {
            return userWarehouseId > 0;
        } else if (action.key === downloadStatement.key) {
            return isDownloadStatementAllowed(frontend_controller);
        } else if (action.key === verfiyUserAddressProof.key) {
            return getIsToShowAddressVerifyUser({ loggedUserFrontendController: frontend_controller, partner })
        } else if (action.key === verfiyUser.key) {
            return getIsToShowVerificationPendingModal({ loggedUserFrontendController: frontend_controller, partner })
        }
        return true;
    });

    return actions;
};

const getSPActions = ({ partner }) => {
    let actions = [viewProfile, copyProfileLink, viewIDCard, UpdateDetails, acceptTickets, promoteUser, sendAppLink, deleteTeamVendor];

    return actions;
};

const getISPActions = ({ partner }) => {
    let actions = [
        viewProfile,
        copyProfileLink,
        bulkUploadResponses,
        viewIDCard,
        UpdateDetails,
        closeFeasibility,
        shareFeasibilityLink,
        copyFeasibilityLink,
        shareOnWhatsApp,
        acceptTickets,
        promoteUser,
        sendAppLink,
        deleteTeamVendor,
    ];
    actions = actions.filter((action) => {
        if (action.key === bulkUploadResponses.key) {
            return !!partner?.customer_detail;
        }
        return true;
    });
    return actions;
};

const getCSPActions = ({ partner }) => {
    let actions = [viewProfile, copyProfileLink, viewIDCard, UpdateDetails, promoteUser, deleteTeamVendor];

    return actions;
};

const getFEActions = ({ partner }) => {
    let actions = [viewProfile, copyProfileLink, viewIDCard, shareFeasibilityLink, copyFeasibilityLink, promoteUser, deleteTeamVendor];

    return actions;
};

const getSCMActions = ({ partner }) => {
    let actions = [promoteUser, viewProfile, updateTerritories, deleteTeamVendor];

    return actions;
};

const getClusterPSPActions = ({ partner }) => {
    let actions = [viewProfile, copyProfileLink, viewIDCard, promoteUser, demoteUser, acceptTickets, updateTerritories, deleteTeamVendor];

    return actions;
};

const getIwanActions = ({ partner }) => {
    let actions = [UpdateDetails, viewProfile, copyProfileLink, viewIDCard, promoteUser, demoteUser, acceptTickets, deleteTeamVendor];

    return actions;
};

export const scmPartnerActionLookup = getLookupWitHDefaultValue({
    lookup: {
        [sp]: getSPActions,
        [vendors]: getSPActions,
        [isp]: getISPActions,
        [csp]: getCSPActions,
        [fe]: getFEActions,
        [scm]: getSCMActions,
        [cluster_psp]: getClusterPSPActions,
        [iwan]: getIwanActions,
    },
    defaultValue: () => [],
});

const isAllowedAddUsersButton = (role, type) => {
    const allowedCriteria = [isCustomer, isScm, isCustomerAdmin, isCustomerUser];
    return allowedCriteria.some((isAllowed) => isAllowed(role, type));
};
const isAllowedRemoveUserButton = (role, type) => {
    const allowedCriteria = [isCustomer, isScm, isCustomerAdmin];
    return allowedCriteria.some((isAllowed) => isAllowed(role, type));
};

export const HandleAccessForButtons = (buttonName) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const roleId = loggedUser?.role?.id;
    const type = loggedUser.type;
    const lookupForButton = {
        ADD_NEW_TEAM: [isCustomer, isScm, isCustomerAdmin],
        SEND_INVITES: [isCustomer, isScm, isCustomerAdmin, isCluster, isSuperVisor],
        ADD_USERS_IN_TEAM: [isCustomer, isScm, isCustomerAdmin, isCustomerUser],
        TEAMS_ACTIONS: [isCustomer, isScm, isCustomerAdmin],
        USERS_ACTIONS: [isCustomer, isScm, isCustomerAdmin, isCustomerUser],
        USERS_TEAM_DELETE: [isCustomer, isScm, isCustomerAdmin],
        VENDORS_ACTION: [isCustomer, isScm, isCustomerAdmin],
        VENDORS_OPERATIONAL_AREA_EDIT: [isCustomer, isScm, isCustomerAdmin, isCluster, isSuperVisor, isPsp, isSp],
        ISP_OPERATIONAL_AREA_EDIT: [isCustomer, isScm, isCustomerAdmin],
        CSP_OPERATIONAL_AREA_EDIT: [isCustomer, isScm, isCustomerAdmin],
        IS_SHOW_ADD_FE_AND_SERVICE_LOCATION: [isCluster, isSuperVisor, isPsp, isSp],
        CHECK_SERVICEABILITY: [isCluster, isScm, isCustomerGroup],
    };
    return lookupForButton[buttonName].some((isAllowed) => isAllowed(roleId, type));
};

const inviteRadioOptionsForSCM = [
    { label: 'Service Partner', name: 'forUnique', value: 'sp' },
    { label: 'Customer Service Partner', name: 'forUnique', value: 'csp' },
    { label: 'Cluster SCM', name: 'forUnique', value: 'cluster_scm' },
];

const inviteRadioForCluster = [{ label: 'Service Partner', name: 'forUnique', value: 'sp' }];

const inviteRadioForCustomer = [
    { label: 'Vendor', name: 'forUnique', value: 'vendor_resourse_manager' },
    { label: 'Admin', name: 'forUnique', value: 'admin' },
    { label: 'User', name: 'forUnique', value: 'pm' },
];

export const handleRadioOptionsAccordingToLogin = (role, type, selectedCustomerId, isSelectedCustomerFeasibilityEligible) => {
    let options = [];
    if (isScm(role, type) && parseInt(selectedCustomerId) > 0) {
        options = inviteRadioForCustomer;
        if (isSelectedCustomerFeasibilityEligible === 1) options = [...options, { label: 'ISP', name: 'forUnique', value: 'isp' }];
    } else if (isScm(role, type)) options = inviteRadioOptionsForSCM;
    else if (isCustomerGroup(role, type)) {
        options = inviteRadioForCustomer;
        if (isSelectedCustomerFeasibilityEligible) options = [...options, { label: 'ISP', name: 'forUnique', value: 'isp' }];
    }
    else if (isCluster(role, type) || isSuperVisor(role, type)) options = inviteRadioForCluster;
    return options;
};

export const handleCustomerIdForScmCustomerTab = (role, type, selectedCustomer, customerId) => {
    if (isScm(role, type)) return selectedCustomer;
    else if (isCustomer(role, type) || isCustomerAdmin(role, type) || isCustomerUser(role, type)) return customerId;
    return null;
};

export const GetAddTeamFormFeildByRole = (fieldName) => {
    const { loggedUser } = useSelector((state) => state.auth);
    const roleId = loggedUser.role.id;
    if (fieldName === 'typeCustomer') return roleId === 4 || roleId === 14;
    else if (fieldName === 'radioTypeForSCM') return roleId === 6;
    else if (fieldName === 'customer') return roleId === 6;
};

export const iconLookup = getLookupWitHDefaultValue({
    lookup: {
        INTERNAL: { img: 'groups', class: 'text-white', bgClass: 'bg-scogobgsky', type: 'SCOGO' },
        CUSTOMER: { img: 'account_box', class: 'text-white', bgClass: 'bg-scogogoing', type: 'CUSTOMER' },
        HELPDESK: { img: 'support_agent', class: 'text-white', bgClass: 'bg-scogoorange', type: 'HELPDESK' },
        VENDOR: { img: 'groups', class: 'text-white', bgClass: 'bg-scogobgsky', type: 'VENDOR' },
        SUPPORT: { img: 'groups', class: 'text-white', bgClass: 'bg-scogobgsky', type: 'SUPPORT' },
    },
    defaultValue: { img: 'groups', class: 'text-white', bgClass: 'bg-scogobgsky', type: 'INTERNAL' },
});

export const handleStatusColor = getLookupWitHDefaultValue({
    lookup: {
        'Profile Pending': { class: 'text-scogoorange text-font11' },
        'Verification Pending': { class: 'text-scogo99 text-font11' },
        InActive: { class: 'text-scogoclosed text-font11' },
        Active: { class: 'text-scogo2e text-font11' },
    },
    defaultValue: { class: '' },
});

export const tabAPILookup = ({ type, page, count, limit, query, customerId }) => {
    type = type?.toUpperCase();

    let lookup = {
        INTERNAL: { query, customerId, limit, page, count, type: 'INTERNAL' },
        CUSTOMER: { query, customerId, limit, page, count, type: 'CUSTOMER' },
        HELPDESK: { query, customerId, limit, page, count, type: 'HELPDESK' },
        SUPPORT: { query, customerId, limit, page, count },
        VENDOR: { query, customerId, limit, page, count, type: 'VENDOR' },
        ALL: { query, customerId, limit, page, count },
    };
    if (!lookup?.[type]) return {};
    return lookup?.[type];
};

export const GetUserDropDownLookup = ({ type, customerId }) => {
    const roleLookup = {
        SUPPORT: { customerId, apiType: 'addOnUsers' },
        CUSTOMER: { role: [16], customerId, apiType: 'users' },
        VENDOR: { role: [12], customerId, apiType: '' },
        HELPDESK: { customerId, apiType: 'addOnUsers' },
        INTERNAL: { role: [6], apiType: 'users' },
    };
    if (!roleLookup[type]) return {};
    else return roleLookup[type];
};

export const roleLookup = getLookupWitHDefaultValue({
    lookup: {
        6: { text: 'SCM', className: 'text-maroon' },
        4: { text: 'Owner', className: 'text-scogoclosed' },
        14: { text: 'Admin', className: 'text-scogoorange' },
        15: { text: 'User', className: 'text-scogo2e' },
        16: { text: 'NOC', className: 'text-scogoprimary' },
        17: { text: 'Customer', className: 'text-scogobgsky' },
    },
    defaultValue: { text: 'N/A', className: '' },
});

export const agentIsInList = (agentId, list) => {
    return list.find((agent) => agent.id === agentId);
};

export const findMatchingRole = ({ destTeam, agentRole }) => {
    const destTeamType = destTeam.type;
    const roleInWhichUserCanTransfer = GetUserDropDownLookup({ type: destTeamType });
    const eligibleRoleArray = roleInWhichUserCanTransfer?.role;
    return !eligibleRoleArray.includes(agentRole);
};

export const isUserAllowedInTeam = (roleId, userType, teamType) => {
    const eligibilityMatrix = getLookupWitHDefaultValue({
        lookup: {
            INTERNAL: [isScm, isCluster, isSuperVisor],
            SUPPORT: [isCustomer, isCustomerAdmin, isCustomerUser],
            HELPDESK: [isCustomer, isCustomerAdmin, isCustomerUser],
            CUSTOMER: [isEndUser],
            VENDOR: [isSp],
        },
        defaultValue: [],
    });
    const eligibilityCriteria = eligibilityMatrix[teamType];
    return eligibilityCriteria.some((isAllowed) => isAllowed(roleId, userType));
};

export const handleErrorForTeamsDragAndDrop = ({ destList, sourceTeam, destTeam, agent }) => {
    let isOk = true,
        message = '',
        showToast = false;
    if (agentIsInList(agent.id, destList)) {
        message = `${agent.first_name} ${agent.last_name} is already in ${destTeam.name}`;
        isOk = false;
        showToast = true;
    }
    if (isOk && sourceTeam.fk_customer_id !== destTeam.fk_customer_id) {
        isOk = false;
    }
    if (isOk && !isUserAllowedInTeam(agent.role, agent.type, destTeam.type)) {
        isOk = false;
        message = 'User cannot transfer';
        showToast = true;
    }
    return { isOk, message, showToast };
};

export const OnSubmitAddTeamApiCallData = ({ customerId, teamActiveTab, selectedCustomer }) => {
    let isToCall = true;
    if (teamActiveTab?.key === 'scmCustomer' && !selectedCustomer) isToCall = false;
    const customerIdIfSCMCUstomer = teamActiveTab?.key === 'scmCustomer' ? selectedCustomer : customerId;
    const tabName = teamActiveTab?.apiCallType;
    let apiCallData = tabAPILookup({ customerId: customerIdIfSCMCUstomer, type: tabName, page: 1, limit: 12, count: true });
    return { apiCallData, isToCall };
};

export const isClusterOrPspUser = (roleId, type) => {
    let userType = '';
    if (isCluster(roleId, type)) userType = 'CLUSTER';
    else if (isPsp(roleId, type)) userType = 'PSP';
    else if (isSuperVisor(roleId, type)) userType = 'Supervisor';
    return userType;
};

export const getSpSendInvitePayload = ({ formValues, applyAs, isJoiningAsSP, isJoiningAsISP, isJoiningAsIwan, isCompanySelected }) => {
    const sp_use_cases = formValues?.sp_use_cases?.map((elem) => elem.value);
    let services_states_cities = [];
    if (Array.isArray(formValues?.services_states_cities)) {
        services_states_cities = formValues.services_states_cities.map((elem) => elem.value);
    }
    const sp_gst_state = actualValueForDropdown(formValues.sp_gst_state) || '';

    let payload = {
        ...formValues,
        sp_use_cases,
        services_states_cities,
        sp_gst_state,
        email: formValues.email,
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        mobile: formValues.mobile,
        sp_applied_as: applyAs,
        sp_address_line_1: formValues.sp_address_line_1,
        sp_address_line_2: '',
        sp_name: formValues.sp_name,
    };

    if (formValues.fk_cluster_id) {
        payload.fk_cluster_id = actualValueForDropdown(formValues.fk_cluster_id);
    }
    if (formValues.sp_pan_card_image) {
        payload.sp_pan_card_image = formValues.sp_pan_card_image;
    }
    if (formValues.fe_adhar_card_image) {
        payload.fe_adhar_card_image = formValues.fe_adhar_card_image;
    }
    if (formValues.adhar_number) {
        payload.adhar_number = formValues.adhar_number;
    }
    if (formValues.sp_pan_card_number) {
        payload.sp_pan_card_number = formValues.sp_pan_card_number;
    }
    if (formValues.sp_city_ids) payload.sp_city_ids = actualValueForDropdown(formValues.sp_city_ids);
    payload.customer_id = 0;
    payload.apply_as_fe = 1;

    if (formValues?.sp_gst_state?.label && formValues.sp_gst_no && formValues.sp_gst_state.label.toString().toLowerCase().replace(/\s+/g, '') === 'maharashtra') {
        payload.sp_applicable_gst_type = 1;
    } else {
        payload.sp_applicable_gst_type = 2;
    }

    if (!isCompanySelected) payload.sp_name = `${formValues.first_name ? formValues.first_name : ''} ${formValues.last_name ? formValues.last_name : ''}`;

    payload.type = 'SP';
    if (isJoiningAsSP) {
        payload.type = 'SP';
    }
    if (isJoiningAsISP) {
        payload.type = 'ISP';
    }
    if (isJoiningAsSP && isJoiningAsISP) {
        payload.type = 'SPISP';
    }
    if (isJoiningAsIwan) {
        payload.employee_type = 'EMP';
        payload.type = 'SP';
    }

    if (!['', undefined, null].includes(formValues?.payment_ratio) && !isNaN(formValues.payment_ratio) && isJoiningAsIwan && parseFloat(formValues.payment_ratio) > 0) {
        payload.payment_ratio = parseFloat(parseFloat(formValues.payment_ratio / 100).toFixed(2));
    }

    if (!isJoiningAsIwan) {
        delete payload.payment_ratio;
        delete payload.fk_cluster_id;
    }

    return payload;
};

export const defaultRowsPerPage = 10;
export const defaultPage = 1;
